import {
  CHECK_ELIGIBILITY,
    FETCH_JOBTITLES,
    FETCH_CARBRANDS,
    FETCH_CARMODELS,
    FETCH_PROPOSALS,
    FETCH_PROPOSAL
  } from '../constants/actionTypes'
  export const proposals = (state = {jobTitles: [], brands: [], models: [], proposals: []}, action) => {
    switch (action.type) {
      case CHECK_ELIGIBILITY: 
        return {
          ...state, eligibility: action.payload
        };
      case FETCH_JOBTITLES:
        return {
          ...state, jobTitles: action.payload
        };
      case FETCH_CARBRANDS:
        return {
          ...state, brands: action.payload
        };
        case FETCH_CARMODELS: 
        return {
          ...state, models: action.payload
        };
        case FETCH_PROPOSALS:
          return {
            ...state, proposals: action.payload
          };
        case FETCH_PROPOSAL:
          return {
            ...state, proposal: action.payload
          };
      default:
        return state;
    }
  }
  