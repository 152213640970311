import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

Geral: {
 paddingTop: "10px", 
 padding: "10px",
 backgroundColor:"white",
 marginTop: "6%",
 height: "auto",
 width: "70%",
 margin: "auto",
 marginLeft: "30px",
 minHeight: "auto",
 [theme.breakpoints.down('md')]: {
    width: "80%",
    margin: "auto",
    marginTop : "2%",
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: "200px",
    width: "90%",
    marginTop: "4%",
    margin: "auto"
  },
},

cabeçalho: {
 
  backgroundColor: "gray",
  MarginBottom: "15px",
  borderBottom: "solid 2px",
},

suasSolicitaçoes: {
  width: "35%", 
  margin: "auto",
  [theme.breakpoints.down('sm')]: {
    width: "50%"
  },
},

}));