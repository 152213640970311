import { useState ,useEffect } from 'react'
import RequestTable from './Table'
import Details from './Details'
import {getRequests} from '../../../actions/agilpay'
import {useDispatch, useSelector} from 'react-redux'


function Agilpay() {
    const dispatch = useDispatch()
    const {requests, completes} = useSelector(state => state.agilpay)
    const [request, setRequest] = useState(null)
    useEffect(() => {
        dispatch(getRequests())
    }, [dispatch])

    return (
        <>{!request ? <RequestTable requests={requests} setRequest={setRequest} completes={completes}/> : <Details request={request} setRequest={setRequest}/>}</> )
}

export default Agilpay
