import React, { useState } from 'react'
import { TextField, Button, Typography, CircularProgress, Paper, Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {changePass} from "../../../../actions/auth";
import Box from '@material-ui/core/Box';
import useStyles from "./styles"
import Input from '../../Input'



export default function ChangePass() {
    const dispatch = useDispatch();
    const classes = useStyles()
    const [userData, setUserData] = useState({
        newPassword: "",
        confimPassword: "",
        oldPassword: "",
    });

    const [showPassword, setShowPassword] = useState({old:false, new:false, confirm:false});
    const handleShowPassword = (e)=>  setShowPassword((prevShowPassword) => ({...prevShowPassword, [e]:!prevShowPassword[e]}) );
    const handleSubmit = (e)=>{
        e.preventDefault();
        //Check if you're editing the post or creating a new one;
        try{
          // console.log(postData.message);//stateToHTML(convertFromRaw(JSON.parse(postData.message)))
        dispatch(changePass(userData));
      }catch(e){
        console.log(e)
        alert(e.message);
      }
        // clear();
        };

    return (
        <Box boxShadow={6} className={classes.changePass}>
          <div>
            <Typography className={classes.fontFace} align="center" variant="h5" style={{width: "60%", margin: "auto", paddingTop: "15px"}} >Alterar Senha</Typography>
          </div>
          
            <Grid container spacing={2} style={{width: "80%", margin: "auto", marginTop: "15px",}}>
            <Input className={classes.inputs} id="old" name='password' label='Senha Atual' handleChange={(e) => setUserData({ ...userData, oldPassword: e.target.value })} type={showPassword.old ? 'text' : 'password'} handleShowPassword={()=> handleShowPassword('old')}/>

            <Input className={classes.inputs} style={{marginTop: "20px"}} name='password' label='Nova Senha' handleChange={(e) => setUserData({ ...userData, newPassword: e.target.value })} type={showPassword.new ? 'text' : 'password'} handleShowPassword={()=> handleShowPassword('new')}/>

            <Input className={classes.inputs} name='password' label='Confirme a Senha' handleChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })} type={showPassword.confirm ? 'text' : 'password'} handleShowPassword={()=> handleShowPassword('confirm')}/>

            <Button style={{width: "100", marginTop: "15px", backgroundColor: "#00d2c6", }} variant="contained" color="primary" size="large" onClick={handleSubmit} fullWidth>Enviar</Button>
            </Grid>
          
          
        </Box>
        
    )
}