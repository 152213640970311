import React from "react";
import moment from "moment";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import {
  changeAvatar,
  fetchImage,
  fetchLogo,
  changeLogo,
  isAdmin,
} from "../../../actions/auth";
import { triggerAlert } from "../../../actions/alert";
import UserRequests from "./UserRequests/UserRequests";
import { AlertCustom } from "../../Alert";
import { useDispatch } from "react-redux";

const Profile = (props) => {
  const user = JSON.parse(localStorage.getItem("profile")).result;
  if (!user.hasOwnProperty("isFranqueado")) user.isFranqueado = true;
  const classes = useStyles();
  const [avatar, setAvatar] = useState(null);
  const [logo, setLogo] = useState(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    fetchImage(user._id)
      .then((res) => {
        setAvatar(window.URL.createObjectURL(res));
      })
      .catch((err) => {
        console.log(err);
      });
    !user.isFranqueado &&
      fetchLogo(user._id, "user")
        .then((res) => {
          setLogo(window.URL.createObjectURL(res));
        })
        .catch((e) => {
          console.log(e);
        });
  }, []);

  const handleImgChange = (file, type = "avatar") => {
    if (file && file.size < 2097152) {
      const data = new FormData();
      data.append("selectedFile", file);
      type === "avatar" ? changeAvatar(data) : changeLogo(data);
      dispatch(
        triggerAlert(
          "Imagem alterado com sucesso, em alguns minutos essa mudança será refletida.",
          "success"
        )
      );
    } else {
      dispatch(
        triggerAlert("Tamanho da Imagem deve ser menor que 2MB.", "error")
      );
    }
  };

  return (
    <>
      <AlertCustom />
      <Box
        className={classes.Geral}
        style={{ position: "absolute", marginBottom: "0" }}
        left="0"
        right="0"
      >
        <Grid className={classes.gridStyle} container direction="column">
          <Grid item lg={12} sm={12}>
            <Box boxShadow={5} className={classes.card} {...props}>
              <CardContent>
                <Box alignItems="center" display="flex" flexDirection="column">
                  <Avatar
                    style={{
                      marginTop: "20px",
                      height: "120px",
                      width: "120px",
                    }}
                    src={avatar}
                  />
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h3"
                    style={{ marginTop: "20px" }}
                  >
                    {user.name}
                  </Typography>
                  <Typography color="textSecondary" variant="body1">
                    {`Franqueado`}
                  </Typography>
                  <Typography color="textSecondary" variant="body1">
                    {`${moment().format("hh:mm A")} GMT-3`}
                  </Typography>
                  {!user.isFranqueado && (
                    <img src={logo} alt="logo" style={{ width: "150px" }} />
                  )}
                </Box>
              </CardContent>

              <CardActions display="flex" className={classes.buttonsDiv}>
                <Button
                  className={classes.buttons}
                  variant="text"
                  fullWidth
                  color="primary"
                  component="label"
                >
                  Foto de Perfil
                  <input
                    multiple={false}
                    type="file"
                    hidden
                    onChange={(e) =>
                      handleImgChange(e.target.files[0], "avatar")
                    }
                  />
                </Button>
                {!user.isFranqueado && (
                  <Button
                    className={classes.buttons}
                    variant="text"
                    fullWidth
                    color="primary"
                    component="label"
                  >
                    Alterar Logo
                    <input
                      multiple={false}
                      type="file"
                      hidden
                      onChange={(e) =>
                        handleImgChange(e.target.files[0], "logo")
                      }
                    />
                  </Button>
                )}
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="secondary"
                  size="small"
                  to="/user/changepass"
                  component={Link}
                  fullWidth
                >
                  Trocar Senha
                </Button>
              </CardActions>
            </Box>
          </Grid>
          <Grid item lg={12} sm={12}>
            <UserRequests />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Profile;
