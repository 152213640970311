import { AUTH, LOGOUT, FETCH_USER_REQUESTS } from "../constants/actionTypes";
const authReducer = (state = { authData: null, requests: []}, action) => {
  switch (action.type) {
    case AUTH:
      localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
      return { ...state, authData: action?.data };
    case LOGOUT:
      localStorage.clear();
      return { ...state, authData: null };
    case FETCH_USER_REQUESTS:
      return { ...state, requests: action.payload };
    default:
      return state;
  }
};

export default authReducer;
