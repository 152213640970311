import React from 'react';
import { Container } from "@material-ui/core";
import { MARKETING, MARKETING_POST , REQUESTS, REQUEST_POST, PROFILE} from "./constants/routes"


import { BrowserRouter, Switch, Route,  Redirect } from 'react-router-dom';

import NavBar from './components/NavBar/NavBar';
import Marketing from './components/Marketing/Marketing';
import Form from './components/Marketing/Form/Form';
import EnhancedTable from './components/Marketing/Requests/EnhancedTable/EnhancedTable';
import Request from './components/Marketing/Requests/Form/Request';
import Auth from './components/Auth/Auth';
import PostDetails from './components/Marketing/PostDetails/PostDetails';
import RequestDetails from './components/Marketing/Requests/Details/RequestDetails';
import {isAdmin} from './actions/auth';
import ChangePass from './components/Auth/Profile/Password/ChangePass';
import Feedback from './components/Auth/Profile/Feedback/Feedback';
import Home  from './components/Home/Home';
import AvisoForm from './components/Auth/Profile/Informativo/Form';
import Profile from './components/Auth/Profile/Profile';
import Admin from './components/Admin/Admin';
import Tabelas from './components/AgilPay/Tabelas'
import * as Proposals from './components/Proposals';
// import {default as ProposalForm} from './components/Proposals/Creditas/Form'
// import Proposals from './components/Proposals/Creditas/Proposals';
import MaquininhaRequest from './components/AgilPay/Solicitação/Form' 
import Agilpay from './components/AgilPay/Solicitação'
import Maintenance from './maintenance';
import { AlertCustom } from './components/Alert';

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  }

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  }
}


const auth = (status ,component, redirect='/auth') =>{
    return (status ? (component()) : (<Redirect to={redirect}/>)) 
}



const App = ()=> {
    const maintenance = false;
    if(maintenance) localStorage.clear();
    const user = () => JSON.parse(localStorage.getItem('profile'));
    return (
        <BrowserRouter>
         <NavBar/>
         <AlertCustom/>
        <Container maxidth="xl">
            {maintenance ? <Maintenance/> : (
            <Switch>
                <Route path="/auth" exact component={()=>auth(!user() || isAdmin(), Auth, '/')} />
                <Route path="/" exact component={()=>auth(user(), Home)} />
                <Route path= {`/${MARKETING}`} exact component={()=>auth(user(), Marketing)}/>
                <Route path={`/${MARKETING}/search`} exact component={()=>auth(user(), Marketing)}/>
                <Route path={`/${MARKETING}/:id`} component={()=>auth(user(), PostDetails)}/>
                <Route path={`/${MARKETING_POST}`} exact component={()=>auth(isAdmin(), Form)}/>
                <Route path={`/${MARKETING_POST}/:id`} component={()=>auth(isAdmin(), Form)}/>
                <Route path={`/${REQUESTS}`} exact component={()=>auth(isAdmin(), EnhancedTable)}/>
                <Route path={`/${REQUEST_POST}`} exact component={()=>auth(user(), Request)}/>
                <Route path={`/${PROFILE}`} exact component={()=>auth(user(), Profile)}/>
                <Route path={"/admin"} exact component={()=>auth(isAdmin(), Admin)}/>
                <Route path={`/${PROFILE}/changepass`} exact component={()=>auth(user(), ChangePass)}/>
                <Route path={`/${PROFILE}/feedback`} exact component={()=>auth(user(), Feedback)}/>
                <Route path={`/${PROFILE}/aviso`} exact component={()=>auth(isAdmin(), AvisoForm)}/>
                <Route path={`/request/:id`} component={()=>auth(user(), RequestDetails)}/>
                <Route path={`/tabelas`} component={()=>auth(user(), Tabelas)}/>
                <Route path={`/proposal`} exact component={()=>auth(user(), Proposals.Creditas.Proposals)}/>
                <Route path={`/proposal/create`} exact component={()=>auth(user(), Proposals.Creditas.Form)}/>
                <Route path={`/UCI/proposal/create`} exact component={()=>auth(isAdmin(), Proposals.UCI.Form)}/>
                <Route path={`/agilpay/proposal`} exact component={()=>auth(isAdmin(), MaquininhaRequest)}/>
                <Route path={`/agilpay/proposals`} exact component={()=>auth(isAdmin(), Agilpay)}/>
            </Switch>)}
        </Container>
        </BrowserRouter>
    );
}

export default App;
