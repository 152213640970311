import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
  /*   Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary, */
  Grid,
  IconButton,
  Box,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";
// import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// import { Archive, ExpandMore } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import {
  getPost,
  fetchBlob /* , getPostsBySearch */,
} from "../../../actions/posts";
import useStyles from "./styles";

const PostDetails = () => {
  const { post, /*  posts, */ isLoading } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  // const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [blobs, setBlobs] = useState([]);

  const loadBlobs = async (post) => {
    try {
      const blobes = [];
      for (const file of post.marketingFile) {
        const blob = await fetchBlob(file._id);
        blobes.push(blob);
      }
      setBlobs(blobes);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    dispatch(getPost(id));
    // eslint-disable-next-line
  }, [id]);
  // let recommendedPosts = [];

  useEffect(() => {
    if (
      post?._id === id &&
      post?.marketingFile.length > 0 &&
      blobs.length === 0
    )
      loadBlobs(post);
  }, [post]);
  // useEffect(() => {
  //   if (post) {
  //     dispatch(getPostsBySearch({ tags: post?.tags.join(",") }));
  //   }
  //   // eslint-disable-next-line
  // }, [post]);

  //if (posts) recommendedPosts = posts.filter(({ _id }) => _id !== post._id);
  if (isLoading) {
    return (
      <Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size="7em" />
      </Paper>
    );
  }
  if (!post) return null;

  const download = (type, url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", type); //or any other extension
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      link.parentNode.removeChild(link);
    }, 10);
  };

  // const openPost = (_id) => history.push(`/posts/${_id}`);
  var message = null;
  try {
    message = stateToHTML(convertFromRaw(JSON.parse(post.message)));
  } catch (e) {
    console.log("Post has no or invalid message");
  }

  if (message) {
    message = `<Box style="text-align:center; width:100%">${message}</Box>`;
  }

  return (
    <Paper style={{ padding: "20px", borderRadius: "15px" }} elevation={6}>
      <Box display="flex" alignItems="center" className={classes.card}>
        <Box className={classes.section}>
          <div>
            <Typography
              className={classes.fontSize}
              style={{ fontFamily: "NoirPro" }}
              align="center"
              variant="h4"
              component="h1"
            >
              {post.title}
            </Typography>
            <Typography
              align="center"
              gutterBottom
              variant="subtitle2"
              color="textSecondary"
            >
              {post.tags?.map((tag) => `#${tag} `)}
            </Typography>
          </div>
          {message && (
            <Box
              className={classes.message}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}

          <Box className={classes.imageSection}>
            <Typography className={classes.heading}>
              Aperte para baixar as Imagens:
            </Typography>

            <Grid container direction="row">
              {blobs.map((blob, i) => {
                const type = blob.type.slice(0, blob.type.indexOf("/"));
                const format = blob.type.slice(
                  blob.type.indexOf("/") + 1,
                  blob.type.length
                );
                const fileUrl = window.URL.createObjectURL(blob);
                var body;

                if (type === "image") {
                  body = (
                    <div>
                      {" "}
                      <img
                        onClick={() => download(type, fileUrl)}
                        width="300"
                        alt="imageX"
                        src={fileUrl}
                      />
                      {post.showNames && <p style={{'textAlign':"center"}}>{post.marketingFile[i].filename}</p>}
                    </div>
                  );
                } else if (type === "video") {
                  body = (
                    <Box style={{ height: "400", width: "100%" }}>
                      <video height="350" width="auto" controls>
                        <source src={fileUrl} type={blob.type} />
                      </video>
                      <IconButton onClick={() => download(type, fileUrl)}>
                        <GetAppIcon />
                      </IconButton>
                    </Box>
                  );
                } else {
                  body = (
                    <FileCopyIcon
                      onClick={() => download(type, fileUrl)}
                      style={{ fontSize: "8rem", color: "#00d2c6" }}
                      className={classes.file}
                    />
                  );
                }

                return (
                  <Grid key={i} item xs={4}>
                    {body}
                  </Grid>
                );
              })}
              {blobs.length !== post.marketingFile.length && (
                <CircularProgress />
              )}
            </Grid>
          </Box>

          <Typography style={{ paddingTop: 20 }} variant="h6">
            Criado por: {post.name}
          </Typography>
          <Typography variant="body1">
            {moment(post.createAt).format("DD/MM/YYYY")}
          </Typography>
          <Divider style={{ margin: "20px 0" }} />
        </Box>
      </Box>
      {/* {recommendedPosts.length ? (
        <Box>
          <Typography gutterBottom variant="h5">
            Você pode também gostar de:
          </Typography>
          <Divider />
          <Box className={classes.recommendedPosts}>
            {recommendedPosts.map(
              ({ title, name, likes, selectedFile, _id }) => (
                <Paper
                  style={{
                    padding: "10px",
                    margin: "20px",
                    cursor: "pointer",
                    border: "1px solid",
                    borderRadius: "10px",
                  }}
                  onClick={() => openPost(_id)}
                  key={_id}
                >
                  <Typography gutterBottom variant="h6">
                    {title}
                  </Typography>
                  <Typography gutterBottom variant="subtitle1">
                    Likes: {likes.length}
                  </Typography>
                  <img src={selectedFile[0]} alt={title} width="200px" />
                </Paper>
              )
            )}
          </Box>
        </Box>
      ) : null} */}
    </Paper>
  );
};

export default PostDetails;
