import React from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  Table,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { completeRequest } from "../../../../actions/agilpay";
import { useDispatch } from "react-redux";
//Details of a request, show client, date, status, etc
import { useHistory } from "react-router-dom";
import useStyles from "./styles";

const TableElement = ({ field, value }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.tableRow}>
      <TableCell>
        <Typography>{field}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{value}</Typography>
      </TableCell>
    </TableRow>
  );
};

function Details({ request, setRequest }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = () => {
    dispatch(completeRequest(request._id, history));
  };

  const getDate =  (date) =>{
    var dateObj = new Date(date);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    
    return   day + "/" + month + "/" +year;
  }

  const classes = useStyles();
  return (
    <Paper elevation={5}>
      <Box className={classes.container}>
        <Button
          color="primary"
          style={{ margin: "5px" }}
          variant="contained"
          onClick={() => setRequest(null)}
        >
          Voltar
        </Button>
        {!request.complete && (
          <Button
            style={{ margin: "5px", backgroundColor: "green", color: "white" }}
            variant="contained"
            onClick={handleClick}
          >
            Completar
          </Button>
        )}
      </Box>
      <Box display="flex">


        <Box display="inline">
          <Typography className={classes.fontFaceNP} variant="h3">
            Detalhes
          </Typography>
          <Typography>Cliente: {request.creator.name} </Typography>
          <Typography>Codigo: {request.creator.codigo}</Typography>
          <Typography>
            Data: {getDate(request.createAt)}
          </Typography>
         
        </Box>


        <Box display="inline">
          <Typography className={classes.fontFaceNP} variant="h3">Pagamento:</Typography>
        <Typography>
          Metodo de Pagamento Optado: {request.payment.method === "Cartão"
            ? `Cartão ${request.payment.term}`
            : "Pix."}
        </Typography>
        <Typography>
            {request.agilzinha> 0 && `Agilzinha: ${request.agilzinha}`}
          </Typography>
          <Typography>{request.agilpay>0 && `Agilpay: ${request.agilpay}`}</Typography>
        <Typography>
          Email: {request.creator.email}
        </Typography>
        </Box>



      </Box>

      <Table className={classes.table}>
        <TableElement field="Rua: " value={request.address.street} />
        <TableElement field="Número: " value={request.address.number} />
        <TableElement field="Bairro: " value={request.address.neighborhood} />
        <TableElement field="Cidade: " value={request.address.city} />
        <TableElement field="Estado: " value={request.address.state} />
        <TableElement field="CEP: " value={request.address.cep} />
      </Table>
    </Paper>
  );
}

export default Details;
