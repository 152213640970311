import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Avatar,
  MenuList,
  Box,
  Paper,
} from "@material-ui/core";
import { PROFILE } from "../../constants/routes";

import { Link, useHistory, useLocation } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";

import useStyles from "./styles";
import agilLogo from "../../images/ConectaLogo.png";
import { isAdmin } from "../../actions/auth";

import { END_LOADING } from "../../constants/actionTypes";

const NavBar = () => {
  const classes = useStyles();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    history.push("/");
    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
    setUser(JSON.parse(localStorage.getItem("profile")));
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <AppBar className={classes.appBar} color="secondary">
        {user && <Sidebar />}
        <Link to="/" className={classes.brandContainer}>
          <img
            className={classes.image}
            src={agilLogo}
            alt="memories"
            height="100px"
          />
        </Link>
        <Toolbar className={classes.toolbar}>
          {user ? (
            <div className={classes.profile}>
              {isAdmin() && (
                <>
                  <Paper elevation={3} className={classes.button}>
                    <Button
                      component={Link}
                      to="/user/aviso"
                      className={classes.leterring}
                    >
                      Avisos
                    </Button>
                  </Paper>
                  <Paper elevation={3} className={classes.button}>
                    <Button
                      component={Link}
                      to="/auth"
                      className={classes.leterring}
                    >
                      Cadastrar
                    </Button>
                  </Paper>
                  <Paper elevation={3} className={classes.button}>
                  <Button
                    component={Link}
                    to="/admin"
                    className={classes.leterring}
                  >
                    Painel
                  </Button>
                </Paper>
                </>)}
              {!user && null}
              <Paper elevation={3} className={classes.button}>
                <Button
                  onClick={logout}
                  to="/auth"
                  className={classes.leterring}
                >
                  Sair
                </Button>
              </Paper>
            </div>
          ) : (null)}

        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
