import React from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";

export const AlertCustom = () => {
  const [alert, setAlert] = React.useState({ message: "", severity: "success" });
  const { message, severity, open } = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if(open) setAlert({ message, severity });
  }, [message, severity]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "CLOSE_ALERT" });
  };

  return (
    <>
      <Snackbar open={open} onClose={handleClose} autoHideDuration={6000}>
        <Alert onClose={handleClose} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};
