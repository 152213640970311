import React, { useState } from 'react'
import { TextField, Button, Typography, Grid, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';


import useStyles from "./styles"
import { sendFeedback } from '../../../../actions/auth';


export default function ChangePass() {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [sending, setSending] = useState(false);
    const [postData, setPostData] = useState({
        title: "",
        message: "",
        sector: "Geral",
    });
    const [error, setError] = useState({
        title: null,
        message: null,
      });
    const clear = () => {
        setError({
            title: null,
            message: null,
        });
        setPostData({
            title: "",
            message: "",
            sector: "Geral",
        });
    }

    const handleValidation = () => {
        const errorRef = {
          title: null,
          message: null,
        };
        if (postData.title.length === 0)
          errorRef.title = "Seu Título não pode estar vazio.";
        if (postData.title.length > 50)
          errorRef.title = "Seu Título deve conter no máximo 50 carecteres.";
        if (postData.message.length === 0)
          errorRef.message = "Por favor descreva seu problema.";
    
        setError(errorRef);
    
        if (
          errorRef.title ||
          errorRef.message
        )
          throw new Error("Campo Invalido");
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        //Check if you're editing the post or creating a new one;
        try {
            if (sending) alert("Aguarde enquando enviamos sua resposta!");
            else {
                handleValidation();
                dispatch(sendFeedback(postData, history));
                setSending(true);
            }
        } catch (e) {
            setSending(false);
        }
        // clear();
    };



    return (
        <Box boxShadow={2} className={classes.paper}>
            
            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} lg={12} onSubmit={(e) => { e.preventDefault(); }}>

                <Typography className={classes.fontFaceNP} variant="h5">Deixe sua Opinião</Typography>
                <Alert severity="info">Este formulario é uma forma de contatar o executivo da Mais Ágil, por favor faça solicitações de suporte tecnico pelo numero: (47) 9103-4686</Alert>
                <TextField error={error.title ? true : false} name="title" variant="outlined" label={error.title ? error.title : "Título"} fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} />

                <TextField  error={error.message ? true : false} multiline={true} rows='5' name="message" variant="outlined" label={error.message ? error.message : "Descrição"} fullWidth value={postData.message} onChange={(e) => setPostData({ ...postData, message: e.target.value })} />

                <Grid direction="row" container spacing={6} justify="center">

                    <Grid item className={classes.tipoDeMaterial}>

                        <InputLabel id="demo-simple-select-helper-label">Setor</InputLabel>

                        <NativeSelect
                            value={postData.type}
                            onChange={(e) => setPostData({ ...postData, sector: e.target.value })}
                            inputProps={{
                                name: 'sector',
                                id: 'type-native-helper',
                            }}
                        >
                            <option value={"Geral"}>Geral</option>
                            <option value={"Expansão"}>Expansão</option>
                            <option value={"Financeiro"}>Financeiro</option>
                            <option value={"Marketing"}>Marketing</option>
                            <option value={"Suporte"}>Suporte</option>
                            <option value={"Administrativo"}>Administrativo</option>
                        </NativeSelect>
                    </Grid>

                </Grid>
                <Button className={classes.buttonSubmit} style={!sending ? { width: 100, backgroundColor: "#00d2c9"} : { width: 100, backgroundColor: "grey"}} variant="contained" color="primary" size="large" onClick={handleSubmit} fullWidth>Enviar</Button>
                <Button className={classes.buttonSubmit} style={{ width: 100, backgroundColor: 'rgba(10, 150, 255, 0.7)' }} variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Limpar</Button>
            </form>
        </Box>
    )
}
