import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  media: {
    borderRadius: '20px',
    objectFit: 'cover',
    maxWidth: '100%',
  },

  file: {
    fontSize: "8rem", 
    color: "#00d2c6", 
    '&:hover': {
      borderRadius: "10px",
      backgroundColor: "rgba(12, 12, 12, 0.1)"
    },  
  },
  card: {
    display: 'flex',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
      '& *':{maxWidth: 'auto',},
    },
  },
  section: {
    borderRadius: '20px',
    margin: '10px',
    minWidth: '500px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      minWidth: "auto",
    },
  },
  imageSection: {
    '&:hover':{
      cursor: "pointer",
    },
    '& #row':{
      width: '100%',
    },
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  recommendedPosts: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  loadingPaper: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', borderRadius: '15px', height: '39vh',
  },
  message:{
      fontSize: 20,
      padding: '0px',
      width: "80%",
      margin: "auto",
  },

  heading: {
   fontFamily: "NoirPro",
   padding: "10px"
  },

  fontSize: {
    [theme.breakpoints.down('sm')]: {
    fontSize: "1.3rem",
    }
  },
}));
