import axios from "axios";
// 'http://localhost:3001'
// 'https://materialagil.herokuapp.com/'
// ''https://server.maisagilfranquias.com/'
export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://server.maisagilfranquias.com";

const API = axios.create({ baseURL: API_URL });

API.interceptors.request.use((req) => {
  const profile = localStorage.getItem("profile");

  if (profile) {
    req.headers.authorization = `Bearer ${JSON.parse(profile).token}`;
  }

  return req;
});

export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPostsBySearch = (searchQuery) =>
  API.get(
    `/posts/search?searchQuery=${searchQuery.search || "none"}&tags=${
      searchQuery.tags
    }`
  );
export const createPost = (newPost) => API.post("/posts", newPost);
export const updatePost = (id, updatedPost) =>
  API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const fetchMarketingFile = (id) =>
  API.get(`/posts/file/${id}`, { responseType: "blob" });

export const signIn = (formData) => API.post(`/user/signin`, formData);
export const signUp = (formData) => API.post(`/user/signup`, formData);
export const fetchAvatar = (id) =>
  API.get(`/user/file/${id}`, { responseType: "blob" });
export const fetchLogo = (id, method) =>
  API.get(`/user/logo/${id}`, { responseType: "blob" });
export const changePass = (userData) => API.post(`/user/changepass`, userData);
export const changeAvatar = (avatar) => API.post(`/user/changeavatar`, avatar);
export const sendFeedback = (message) => API.post(`/user/feedback`, message);
export const getUserRequests = () => API.get("/user/requests");
export const changeLogo = (logo) => API.post(`/user/changelogo`, logo);

export const fetchRequests = (complete) =>
  API.get(`/requests?complete=${complete}`);
export const fetchRequest = (id) => API.get(`/requests/${id}`);
export const createRequest = (request) => API.post("/requests", request);
export const deleteRequest = (id) => API.delete(`/requests/${id}`);
export const completeRequest = (id, post) =>
  API.patch(`/requests/${id}/complete`, post);
export const fetchRequestFile = (id) =>
  API.get(`/requests/file/${id}/`, { responseType: "blob" });
// export const fetchImages = (id) => API.get(`/requests/images/${id}`); //TODO: Remove this shit

export const fetchAvisos = () => API.get(`/avisos`);
export const createAviso = (aviso) => API.post(`/avisos`, aviso);
export const deleteAviso = (id) => API.delete(`/avisos/${id}`);

/// creditas

export const fetchJobTitles = () => API.get(`creditas/get/titles`);
export const fetchCarBrands = (year) =>
  API.get(`creditas/get/brands?year=` + year);
export const fetchCarModels = (year, brand) =>
  API.get(`creditas/get/models`, { params: { year, brand } });
export const createProposal = (proposal) =>
  API.post(`creditas/create/proposal`, proposal);
export const fetchProposals = () => API.get(`creditas/user/proposals`);
export const fetchProposal = (id) => API.get(`creditas/user/proposal/${id}`);
export const checkEligibility = (cpf) =>
  API.get(`creditas/checkeligibility?cpf=${cpf}`);

// AgilPay

export const createPedidoMaquina = (pedidoMaquina) =>
  API.post(`agilpay/proposal`, pedidoMaquina);
export const getPedidosMaquina = () => API.get(`agilpay/proposal`);
export const completePedidoMaquina = (id) =>
  API.patch(`agilpay/proposal/${id}/complete`);
