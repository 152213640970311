import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormGroup,
  Tooltip,
  FormControl,
  FormControlLabel,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PhoneField from "./PhoneField";
import { Alert, AlertTitle} from "@material-ui/lab";
import useStyles from "./styles";
import {
  fetchJobTitles,
  fetchCarBrands,
  fetchCarModels,
  createProposal,
  checkEligibility,
  translateFields
} from "../../../../actions/creditas";
import Joi from "joi";
import { triggerAlert } from "../../../../actions/alert";

const collaterals = [
  // collateral em caso de Automovel ou Imovel.
  {
    brand: "",
    model: "",
    modelYear: "2021",
    manufacturingYear: "",
    modelVersion: "",
    borrowerVehicleOwner: true,
    debt: "",
    licensePlate: "",
    numberOfDoors: "",
    fuelType: "",
    accessoryPackage: "",
  },
  {
    value: "",
    debt: 0,
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "BR",
    postalCode: "",
    hasDeed: "NO",
    realEstateType: "HOUSE",
  },
];

const initialValues = {
  productType: "AUTO_REFI",
  purpose: "INVESTMENT_IN_OWN_BUSINESS", //"INVESTMENT_IN_OWN_BUSINESS",
  borrower: {
    fullName: "",
    email: "",
    birthDate: "2000-01-01",
    cellphone: "",
    cellphoneCode: "",
    monthlyIncome: 0,
    professionalStatus: "",
    jobTitle: "",
    timeOfEmployment: "",
    authorizationTerms:
      "Ao Continuar, você autoriza o parceiro Creditas a consultar o seu histórico de crédito e de eventuais pessoas jurídicas das quais você seja sócio(a), no Sistema de Informações de Crédito – SCR do Banco Central do Brasil.",
    postalCode: "",
    cpf: "",
    optIns: {
      whatsApp: false,
    },
  },
  intendedCredit: {
    currency: "BRL",
    amount: "",
  },
  conditions: {
    installment: {
      term: 24,
    },
  },
  collateral: collaterals[0],
};

const timesOfEmployment = [
  { value: "LESS_THAN_ONE_YEAR", label: "Menos de um ano" },
  { value: "BETWEEN_SIX_MONTHS_AND_ONE_YEAR", label: "Entre 6 meses e 1 ano" },
  { value: "BETWEEN_ONE_AND_TWO_YEARS", label: "Entre 1 e 2 anos" },
  { value: "BETWEEN_TWO_AND_THREE_YEARS", label: "Entre 2 e 3 anos" },
  { value: "BETWEEN_THREE_AND_FOUR_YEARS", label: "Entre 3 e 4 anos" },
  { value: "BETWEEN_FOUR_AND_FIVE_YEARS", label: "Entre 4 e 5 anos" },
  { value: "BETWEEN_FIVE_AND_TEN_YEARS", label: "Entre 5 e 10 anos" },
  { value: "MORE_THAN_TEN_YEARS", label: "Mais de 10 anos" },
];

const parcelas = [18, 24, 36, 48, 60];


const autoSchema = Joi.object({
  brand: Joi.string().required(), // ok
  model: Joi.string().required(), // ok
  debt: Joi.alternatives().try(Joi.number(), Joi.string()).required(), // ok
  modelYear: Joi.string().required(), // ok
  manufacturingYear: Joi.string().required(), // ok
  modelVersion: Joi.string().required(), // ok
  borrowerVehicleOwner: Joi.boolean().required(), // ok
  licensePlate: Joi.string().required(), // ok
  numberOfDoors: Joi.number().required(), // ok
  fuelType: Joi.string().required(), // ok
  accessoryPackage: Joi.string().required(), // ok
});

const imovelSchema = Joi.object({
  value: Joi.number().required(),
  debt: Joi.alternatives().try(Joi.number(), Joi.string()).required(),
  address: Joi.string().required(),
  number: Joi.number().required(),
  complement: Joi.any().optional(),
  neighborhood: Joi.string().required(),
  city: Joi.string().required(),
  state: Joi.string().required(),
  country: Joi.string().required(),
  postalCode: Joi.string().required(),
  hasDeed: Joi.string().required(),
  realEstateType: Joi.string().required(),
});

const proposalSchema = Joi.object({
  productType: Joi.string().required(),
  purpose: Joi.string().required(),
  borrower: Joi.object({
    fullName: Joi.string().required(),
    email: Joi.string().required(),
    birthDate: Joi.string().required(),
    cellphone: Joi.string().required(),
    cellphoneCode: Joi.string().required(),
    monthlyIncome: Joi.number().required(),
    professionalStatus: Joi.string().required(),
    jobTitle: Joi.alternatives().conditional("professionalStatus", {
      switch: [{is: "EMPLOYED", then: Joi.string().required()}, {is: "FREELANCER", then: Joi.string().required()}], otherwise: Joi.string().optional()}),  
    timeOfEmployment: Joi.alternatives().conditional("professionalStatus", {
      switch: [{is: "EMPLOYED", then: Joi.string().required()}, {is: "FREELANCER", then: Joi.string().required()}], otherwise: Joi.string().optional()}),
    authorizationTerms: Joi.string().required(),
    postalCode: Joi.string().required(),
    cpf: Joi.string().required(),
    optIns: Joi.object({
      whatsApp: Joi.boolean().required(),
    }),
  }),
  intendedCredit: Joi.object({
    currency: Joi.string().required(),
    amount: Joi.number().required(),
  }),
  conditions: Joi.object({
    installment: Joi.object({
      term: Joi.number().required(),
    }),
  }),
  collateral: Joi.any()
});



const translateJoiMessage = ({context, message, path, type}) => {
  const newType = type.split(".").pop();
  switch (newType) {
    case "required":
      return `${translateFields(context.key)} é obrigatório`;
    case "empty":
      return `${translateFields(context.key)} não pode ser vazio`;
    default:
      return message;
  }
}
// const initialValues = {
//   productType: "AUTO_REFI",
//   purpose: "REAL_ESTATE_RENOVATION", //"INVESTMENT_IN_OWN_BUSINESS",
//   borrower: {
//     fullName: "complete name",
//     email: "teste0000001@gmail.com",
//     birthDate: "1991-01-29",
//     cellphone: "999999999",
//     cellphoneCode: "31",
//     monthlyIncome: 12345.6,
//     professionalStatus: "SELF_EMPLOYED",
//     jobTitle: "",
//     authorizationTerms: "Eu autorizo...",
//     postalCode: "91770552",
//     cpf: "23154674039",
//     optIns: {
//       whatsApp: true,
//     },
//   },
//   intendedCredit: {
//     currency: "BRL",
//     amount: 5001.0,
//   },
//   conditions: {
//     installment: {
//       term: 24,
//     },
//   },
//   collateral: collaterals[0],
// };

//TODO: authorizationTerms and optIns
//TODO: add validation
const Form = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("profile"));
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const { jobTitles, brands, models, eligibility } = useSelector(
    (state) => state.proposals
  );
  const [errors, setErrors] = useState({ amount: false, eligible: false, others: null });
  const [proposalData, setProposalData] = useState(initialValues);
  const [collateral, setCollateral] = useState(collaterals[0]);
  const [hasDebt, setHasDebt] = useState(false);
  const dispatch = useDispatch();

  let freelancerOrSelfEmployed = () =>
    proposalData.borrower.professionalStatus === "SELF_EMPLOYED" ||
    proposalData.borrower.professionalStatus === "FREELANCER";

  useEffect(() => {
    switch (eligibility?.error) {
      case true:
        setErrors({ ...errors, eligible: true });
        break;
      case false:
        setErrors({ ...errors, eligible: false });
        break;
      default:
        if (errors.eligible) setErrors({ ...errors, eligible: false });
    }
  }, [eligibility]);

  useEffect(() => {
    if (!jobTitles.length > 0) dispatch(fetchJobTitles());
  }, [dispatch]);

  // check if all required fields are filled and alert user if not
  const validate = async (data) => {
    try{
      await proposalSchema.validateAsync(data);
      data.productType === "AUTO_REFI" ? await autoSchema.validateAsync(data.collateral) : await imovelSchema.validateAsync(data.collateral);
      return true
    } catch (err) {
      console.log(err.details)
      window.scrollTo(0, 0);
      setErrors({ ...errors, others: translateJoiMessage(err.details[0])});
        dispatch(triggerAlert("Por favor preencha todos os campos", "error"));
        return false;
    }
  }
      

  const handleSubmit = async (e) => {
    e.preventDefault();
    //Check if you're editing the post or creating a new one;
    try {
      if (sending) alert("Por favor aguarde o envio do formulario");
      // console.log(proposalData.message);//stateToHTML(convertFromRaw(JSON.parse(proposalData.message)))
      else{
        setSending(true);
        // deep clone
        let data = JSON.parse(JSON.stringify(proposalData));
        // separate (contry code) from phone number
        data.borrower.cellphoneCode = data.borrower.cellphone.substring(1, 3);
        //extract only the number
        data.borrower.cellphone = data.borrower.cellphone
          .substring(5)
          .replace(/\D/g, "");
        if (data.productType === "AUTO_REFI") {
          data.collateral.model = collateral.model;
          data.collateral.modelVersion = collateral.modelVersion;
          data.collateral.accessoryPackage = collateral.accessoryPackage;
          data.collateral.numberOfDoors = collateral.numberOfDoors;
          data.collateral.fuelType = collateral.fuelType;
          // debt is different for auto refi
          data.collateral.debt = hasDebt
            ? Number(data.collateral.debt.replace(".", "").replace(",", "."))
            : "0";
        }
        // Number conversions
        data.collateral.value && (data.collateral.value = Number(data.collateral.value.replace(".", "").replace(",", ".")))
        data.collateral?.hasDeed === "YES" && (data.collateral.debt = Number(data.collateral.debt.replace(".", "").replace(",", ".")))
        data.borrower.monthlyIncome = Number(
          String(data.borrower.monthlyIncome).replace(".", "").replace(",", ".")
        );
        data.intendedCredit.amount = Number(
          data.intendedCredit.amount.replace(".", "").replace(",", ".")
        );
        data.borrower.postalCode = data.borrower.postalCode.replace("-", "");
        data.borrower.cpf = data.borrower.cpf.replace(/\D/g, "");

        if (!freelancerOrSelfEmployed()) {
          delete data.borrower.jobTitle;
          delete data.borrower.timeOfEmployment;
        }
        console.log(data);
        if(await validate(data)) dispatch(createProposal(setSending, data, history, (e)=>setErrors({...errors, others: e})));
        else setSending(false);
        // .catch((error) => {
        //   if(error.response){
        //   console.log(error.response.data);
        //   if(error.response.data.details){
        //     alert(error.response.data.message+"\n"+error.response.data.details.map((detail) => detail.message).join("\n"));
        //   }
        //   }else{
        //     console.log(error);
        //     alert("Problemas com o servidor, por favor relate o ocorrido ao suporte.");
        //   }
        //   setSending(false);
        // });
      }
    } catch (e) {
      alert(e.message);
      setSending(false);
    }
  };

  if (!user?.result?.name) {
    return (
      <Paper className={classes.paper} elevation={6}>
        <Typography variant="h6" align="center">
          Por favor, entre na sua conta para enviar propostas.
        </Typography>
      </Paper>
    );
  }

  const clear = () => {
    setProposalData(initialValues);
  };

  const handleProductTypeChange = (e) => {
    setProposalData({
      ...proposalData,
      productType: e.target.value,
      collateral:
        e.target.value === "AUTO_REFI" ? collaterals[0] : collaterals[1],
    });
  };

  const handleChange = (e) =>
    setProposalData({ ...proposalData, [e.target.name]: e.target.value });
  const handleStateObjectChange = (key1, key2, value) =>
    setProposalData({
      ...proposalData,
      [key1]: { ...proposalData[key1], [key2]: value },
    });
  const handleCpfChange = (e) => {
    setProposalData({
      ...proposalData,
      borrower: { ...proposalData.borrower, cpf: e.target.value },
    });

    const cpf = e.target.value.replace(/\D/g, "");
    if (cpf.length === 11) {
      dispatch(checkEligibility(cpf));
    } else
      errors.eligible &&
        dispatch({
          type: "CHECK_ELIGIBILITY",
          payload: { error: false, data: null },
        });
  };
  const searchBrands = () => {
    dispatch(fetchCarBrands(proposalData.collateral.modelYear));
  };

  const handleBrandChange = (e) => {
    setProposalData({
      ...proposalData,
      collateral: { ...proposalData.collateral, brand: e.target.value },
    });
    dispatch(fetchCarModels(e.target.value, proposalData.collateral.modelYear));
  };

  const handleOptinChange = (e) => {
    setProposalData({
      ...proposalData,
      borrower: { ...proposalData.borrower, optIns: {whatsApp: e.target.checked} },
    });
  };

  return (
    <Paper className={classes.paper}>
      {errors.others && (
        <Alert severity="error">
          <AlertTitle>Erro</AlertTitle>
          {errors.others}
        </Alert>
      )}
      <form
        autoComplete="off"
        noValidate
        className={`${classes.root} ${classes.form}`}
        lg={12}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Typography className={classes.fontFaceNP} variant="h6" align="center">
          Criação de Proposta
        </Typography>
        <FormControl>
          <InputLabel>Tipo de Proposta: </InputLabel>
          <Select
            name="productType"
            value={proposalData.productType}
            onChange={handleProductTypeChange}
          >
            <MenuItem value={"AUTO_REFI"}>Automovel</MenuItem>
            <MenuItem value={"HOME_REFI"}>Imovel</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Motivo da Proposta: </InputLabel>
          <Select
            name="purpose"
            value={proposalData.purpose}
            onChange={handleChange}
          >
            <MenuItem value={"INVESTMENT_IN_OWN_BUSINESS"}>
              Investimento
            </MenuItem>
            <MenuItem value={"DEBTS_PAYMENT"}>Pagamento de dívidas</MenuItem>
            <MenuItem value={"DEBTS_REFINANCING"}>
              Refinanciamento de dívidas
            </MenuItem>
            <MenuItem value={"REAL_ESTATE_RENOVATION"}>
              Reforma de casa
            </MenuItem>
            <MenuItem value={"GOODS_ACQUISITION"}>Aquisição de Bens</MenuItem>
            <MenuItem value={"OTHERS"}>Outros</MenuItem>
          </Select>
        </FormControl>
        <Typography className={`${classes.fontFaceNP}`} variant="h6">
          Dados do Cliente
        </Typography>
        <Box display="flex">
          <TextField
            name="cpf"
            style={{ flexBasis: "66.6%" }}
            variant="outlined"
            error={errors.eligible}
            label="CPF"
            value={proposalData.borrower.cpf}
            onChange={handleCpfChange}
          />
          {eligibility?.data && (
            <Tooltip title="Elegibilidade">
              <Box style={{ flexBasis: "33.36%" }}>
                <Typography
                  className={classes.fontFaceNP}
                  color={eligibility.data[0].eligible ? "primary" : "secondary"}
                  variant="h6"
                >
                  Auto: {eligibility.data[0].eligible ? "✔️" : "✗"}
                </Typography>
                <Typography
                  className={classes.fontFaceNP}
                  color={eligibility.data[1].eligible ? "primary" : "secondary"}
                  variant="h6"
                >
                  Imóvel: {eligibility.data[1].eligible ? "✔️" : "✗"}
                </Typography>
              </Box>
            </Tooltip>
          )}
        </Box>
        <TextField
          name="fullName"
          variant="outlined"
          label="Nome Completo"
          fullWidth
          value={proposalData.borrower.fullName}
          onChange={(e) =>
            handleStateObjectChange("borrower", "fullName", e.target.value)
          }
        />
        <TextField
          name="postalCode"
          variant="outlined"
          label="CEP"
          fullWidth
          value={proposalData.borrower.postalCode}
          onChange={(e) =>
            handleStateObjectChange("borrower", "postalCode", e.target.value)
          }
        />
        <TextField
          name="email"
          variant="outlined"
          label="Email"
          fullWidth
          value={proposalData.borrower.email}
          onChange={(e) =>
            handleStateObjectChange("borrower", "email", e.target.value)
          }
        />
        <TextField
          name="birthDate"
          type="date"
          label="Data de Nascimento"
          fullWidth
          value={proposalData.borrower.birthDate}
          onChange={(e) =>
            handleStateObjectChange("borrower", "birthDate", e.target.value)
          }
        />
        <PhoneField
          value={proposalData.borrower.cellphone}
          onChange={(e) =>
            handleStateObjectChange("borrower", "cellphone", e.target.value)
          }
        />
        <TextField
          name="monthlyIncome"
          variant="outlined"
          label="Renda Mensal"
          value={proposalData.borrower.monthlyIncome}
          onChange={(e) =>
            handleStateObjectChange("borrower", "monthlyIncome", e.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
        <FormControl>
          <InputLabel>Status Profissional</InputLabel>
          <Select
            name="professionalStatus"
            value={proposalData.borrower.professionalStatus}
            onChange={(e) =>
              handleStateObjectChange(
                "borrower",
                "professionalStatus",
                e.target.value
              )
            }
          >
            <MenuItem value={"CLT"}>CLT</MenuItem>
            <MenuItem value={"SELF_EMPLOYED"}>Autônomo</MenuItem>
            <MenuItem value={"FREELANCER"}>Profissional Liberal</MenuItem>
            <MenuItem value={"BUSINESSMAN"}>Empresário</MenuItem>
            <MenuItem value={"CIVIL_SERVANT"}>Funcionário Público</MenuItem>
            <MenuItem value={"RETIRED"}>Aposentado/Pensionista</MenuItem>
          </Select>
        </FormControl>
        {freelancerOrSelfEmployed() && (
          <>
            <FormControl>
              <InputLabel>Profissão</InputLabel>
              <Select
                name="jobTitle"
                value={proposalData.borrower.jobTitle}
                onChange={(e) =>
                  handleStateObjectChange(
                    "borrower",
                    "jobTitle",
                    e.target.value
                  )
                }
              >
                {jobTitles.length > 0 ? (
                  jobTitles.map((jobTitle, i) => (
                    <MenuItem key={i} value={jobTitle}>
                      {jobTitle}
                    </MenuItem>
                  ))
                ) : (
                  <CircularProgress />
                )}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Tempo de Trabalho</InputLabel>
              <Select
                name="jobTitle"
                value={proposalData.borrower.timeOfEmployment}
                onChange={(e) =>
                  handleStateObjectChange(
                    "borrower",
                    "timeOfEmployment",
                    e.target.value
                  )
                }
              >
                {timesOfEmployment.length > 0 ? (
                  timesOfEmployment.map((time, i) => (
                    <MenuItem key={i} value={time.value}>
                      {time.label}
                    </MenuItem>
                  ))
                ) : (
                  <CircularProgress />
                )}
              </Select>
            </FormControl>
          </>
        )}
        <Typography className={`${classes.fontFaceNP}`} variant="h6">
          Dados do Emprestimo
        </Typography>
        <TextField
          name="amount"
          variant="outlined"
          label="Valor do Crédito"
          helperText={
            proposalData.productType === "AUTO_REFI"
              ? "Valor deve ser maior que R$ 5.000,00"
              : "Valor deve ser maior que R$ 30.000,00"
          }
          error={errors.amount}
          value={proposalData.intendedCredit.amount}
          onChange={(e) => {
            handleStateObjectChange("intendedCredit", "amount", e.target.value);
            // check if amount is valid
            const value = parseFloat(
              e.target.value.replace(".", "").replace(",", ".")
            );
            switch (proposalData.productType) {
              case "AUTO_REFI":
                if (value < 5000) {
                  setErrors({ ...errors, amount: true });
                } else {
                  setErrors({ ...errors, amount: false });
                }
                break;
              case "HOME_REFI":
                if (value < 30000) {
                  setErrors({ ...errors, amount: true });
                } else {
                  setErrors({ ...errors, amount: false });
                }
                break;
              default:
                setErrors({ ...errors, amount: false });
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
        <FormControl>
          <InputLabel>Parcelas</InputLabel>
          <Select
            name="term"
            value={proposalData.conditions.installment.term}
            onChange={(e) =>
              handleStateObjectChange("conditions", "installment", {
                term: e.target.value,
              })
            }
          >
            {parcelas.map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography className={`${classes.fontFaceNP}`} variant="h6">
          Dados do{" "}
          {proposalData.productType === "AUTO_REFI" ? "Automovél" : "Imovel"}
        </Typography>

        {proposalData.productType === "AUTO_REFI" ? (
          <>
            <Box display="flex" className={classes.field} flexDirection="row">
              <TextField
                size="small"
                name="year"
                variant="outlined"
                label="Ano do Veículo"
                value={proposalData.collateral.modelYear}
                onChange={(e) =>
                  handleStateObjectChange(
                    "collateral",
                    "modelYear",
                    e.target.value
                  )
                }
              />
              <Button
                style={{ height: "35px", marginTop: "10px" }}
                size="small"
                variant="contained"
                color="primary"
                onClick={searchBrands}
              >
                Pesquisar
              </Button>
            </Box>
            {brands.length > 0 && (
              <FormControl>
                <InputLabel>Marcas</InputLabel>
                <Select
                  name="brand"
                  value={proposalData.collateral.brand}
                  onChange={handleBrandChange}
                >
                  {brands.map((value, i) => (
                    <MenuItem key={i} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {models.length > 0 && (
              <FormControl>
                <InputLabel>Modelos</InputLabel>
                <Select
                  name="model"
                  value={collateral}
                  onChange={(e) => setCollateral(e.target.value)}
                >
                  {models.map((value, i) => (
                    <MenuItem
                      key={i}
                      value={value}
                    >{`${value.model} / ${value.modelVersion} / ${value.numberOfDoors} Portas / Combustivel: ${value.fuelType} / Acessórios: ${value.accessoryPackage}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl>
              <TextField
                size="small"
                name="year"
                variant="outlined"
                label="Ano de Fabricação do Veículo"
                value={proposalData.collateral.manufacturingYear}
                onChange={(e) =>
                  handleStateObjectChange(
                    "collateral",
                    "manufacturingYear",
                    e.target.value
                  )
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={proposalData.collateral.borrowerVehicleOwner}
                    onChange={(e) =>
                      handleStateObjectChange(
                        "collateral",
                        "borrowerVehicleOwner",
                        e.target.checked
                      )
                    }
                  />
                }
                label="Veículo do Cliente"
              />{" "}
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasDebt}
                    onChange={(e) => {
                      setHasDebt(e.target.checked);
                    }}
                  />
                }
                label="Tem Dívida?"
              />{" "}
            </FormControl>
            {hasDebt && (
              <TextField
                name="debt"
                variant="outlined"
                label="Dívida do Veículo"
                value={proposalData.collateral.debt}
                onChange={(e) =>
                  handleStateObjectChange("collateral", "debt", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            )}
            <TextField
              name="licensePlate"
              variant="outlined"
              label="Placa do Veículo"
              value={proposalData.collateral.licensePlate}
              onChange={(e) =>
                handleStateObjectChange(
                  "collateral",
                  "licensePlate",
                  e.target.value
                )
              }
            />
          </>
        ) : (
          <>
            <TextField
              name="value"
              variant="outlined"
              label="Valor do Imóvel"
              value={proposalData.collateral.value}
              onChange={(e) =>
                handleStateObjectChange("collateral", "value", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
            <TextField
              name="address"
              variant="outlined"
              label="Endereço"
              value={proposalData.collateral.address}
              onChange={(e) =>
                handleStateObjectChange("collateral", "address", e.target.value)
              }
            />
            <TextField
              name="number"
              variant="outlined"
              label="Número"
              value={proposalData.collateral.number}
              onChange={(e) =>
                handleStateObjectChange("collateral", "number", e.target.value)
              }
            />
            <TextField
              name="neighborhood"
              variant="outlined"
              label="Bairro"
              value={proposalData.collateral.neighborhood}
              onChange={(e) =>
                handleStateObjectChange(
                  "collateral",
                  "neighborhood",
                  e.target.value
                )
              }
            />
            <TextField
              name="complement"
              variant="outlined"
              label="Complemento"
              value={proposalData.collateral.complement}
              onChange={(e) =>
                handleStateObjectChange(
                  "collateral",
                  "complement",
                  e.target.value
                )
              }
            />
            <TextField
              name="city"
              variant="outlined"
              label="Cidade"
              value={proposalData.collateral.city}
              onChange={(e) =>
                handleStateObjectChange("collateral", "city", e.target.value)
              }
            />
            <TextField
              name="state"
              variant="outlined"
              label="Estado"
              value={proposalData.collateral.state}
              onChange={(e) =>
                handleStateObjectChange("collateral", "state", e.target.value)
              }
            />
            <TextField
              name="postalCode"
              variant="outlined"
              label="CEP"
              value={proposalData.collateral.postalCode}
              onChange={(e) =>
                handleStateObjectChange(
                  "collateral",
                  "postalCode",
                  e.target.value
                )
              }
            />
            <FormControl>
              <InputLabel>Tem Dívida?: </InputLabel>
              <Select
                name="hasDeed"
                value={proposalData.collateral.hasDeed}
                onChange={(e) =>
                  handleStateObjectChange(
                    "collateral",
                    "hasDeed",
                    e.target.value
                  )
                }
              >
                <MenuItem value={"YES"}>Sim</MenuItem>
                <MenuItem value={"NO"}>Não</MenuItem>
                <MenuItem value={"DO_NOT_KNOW"}>Não sei</MenuItem>
              </Select>
            </FormControl>
            {proposalData.collateral.hasDeed === "YES" && (
              <TextField
                name="debt"
                variant="outlined"
                label="Dívida"
                value={proposalData.collateral.debt}
                onChange={(e) =>
                  handleStateObjectChange("collateral", "debt", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            )}

            <FormControl>
              <InputLabel>Tipo de Proposta: </InputLabel>
              <Select
                name="realEstateType"
                value={proposalData.collateral.realEstateType}
                onChange={(e) =>
                  handleStateObjectChange(
                    "collateral",
                    "realEstateType",
                    e.target.value
                  )
                }
              >
                <MenuItem value={"HOUSE"}>Casa</MenuItem>
                <MenuItem value={"APARTMENT"}>Apartamento</MenuItem>
                <MenuItem value={"COMMERCIAL"}>Comercial</MenuItem>
                <MenuItem value={"LAND"}>Terreno</MenuItem>
                <MenuItem value={"OTHER"}>Outro</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        <Box>
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Tooltip title="Saber Mais">
                <Typography variant="subtitle2">
                  <strong>
                    *Ao Continuar, você autoriza o parceiro Creditas a consultar
                    o seu histórico de crédito e de eventuais pessoas jurídicas
                    das quais você seja sócio(a), no Sistema de Informações de
                    Crédito – SCR do Banco Central do Brasil.
                  </strong>
                </Typography>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle2">
                Para te oferecer a melhor proposta de crédito, solicitamos a
                autorização para consultar o histórico de movimentações
                financeiras atreladas ao seu CPF e de eventuais pessoas
                jurídicas das quais você seja sócio(a) no Sistema de Informações
                de Crédito do Banco Central - SRC. Consigo continuar sem
                autorizar esta consulta? Não. Você precisa autorizar a consulta
                para podermos dar continuidade a sua solicitação Ainda tem
                dúvidas sobre o que é o SCR? Veja o{" "}
                <a href="https://www.bcb.gov.br/acessoinformacao/perguntasfrequentes-respostas/faq_scr">
                  FAQ do Banco Central.
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <FormControlLabel
                control={
                  <Checkbox
                    checked={proposalData.borrower.optIns.whatsApp}
                    onChange={handleOptinChange}
                  />
                }
                label="Desejo ser atualizado pelo Whatsapp."
              />
        </Box>

        <Box>
          <Button
            style={
              !sending
                ? { width: 100, backgroundColor: "#00d2c6" }
                : { width: 100, backgroundColor: "grey" }
            }
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            fullWidth
          >
            Enviar
          </Button>
          <Button
            style={{
              width: 100,
              marginLeft: "4px",
              backgroundColor: "rgba(10, 150, 255, 0.7)",
            }}
            variant="contained"
            color="secondary"
            size="large"
            onClick={clear}
            fullWidth
          >
            Limpar
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default Form;
