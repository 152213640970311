/* eslint-disable no-sequences */
import React from "react";

// ---------------------Material Ui componentes
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import SettingsIcon from "@material-ui/icons/Settings";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import BrushIcon from "@material-ui/icons/Brush";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import KitchenIcon from "@material-ui/icons/Kitchen";
import RedeemIcon from "@material-ui/icons/Redeem";
// ---------------------Material Ui componentes

import { PROFILE } from "../../constants/routes";
import { useHistory } from "react-router-dom";
import {
  MARKETING,
  MARKETING_POST,
  REQUESTS,
  REQUEST_POST,
} from "../../constants/routes";
import { isAdmin } from "../../actions/auth";
import useStyles from "./styles";
import { Grid, Icon, Paper } from "@material-ui/core";
import { fetchImage } from "../../actions/auth";

export default function Sidebar() {
  const user = JSON.parse(localStorage.getItem("profile")).result;
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    btotom: false,
    right: false,
  });
  const [perfilImg, setPerfilImg] = React.useState(null);

  if (!perfilImg && user.avatar) setPerfilImg(fetchImage(user._id));
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={classes.listContent}
      style={{
        paddingTop: "1px",
        paddingBottom: "1",
        margin: "0",
        width: "250px",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* ---------------------------------------CONTEUDO SIDEBAR--------------------------------------------*/}

      {/* -------------------------------------------PERFIL------------------------------------------------------- */}
      <List>
        <Box>
          <Box className={classes.borderPerfil}>
            <ListItem className={classes.userNameList}>
              <Avatar
                className={classes.perfilIcon}
                alt={user?.name}
                href={perfilImg}
              >
                {user.name ? user.name.charAt(0) : "?"}
              </Avatar>
            </ListItem>

            <ListItem style={{ margin: "auto" }}>
              <Typography className={classes.userName} variant="h6">
                {user.name}
              </Typography>
            </ListItem>

            <ListItem
              button
              onClick={() => {
                history.push(`/${PROFILE}/`);
              }}
              style={{ margin: "auto" }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText className={classes.fontFaceSt} primary="Perfil" />
            </ListItem>
          </Box>

          {/* -------------------------------------------MARKETING------------------------------------------------------- */}

          <Paper elevation={5} className={classes.topicBorder}>
            <Typography
              variant="h5"
              className={(classes.fontFaceNP, classes.leterring)}
            >
              Marketing
            </Typography>
          </Paper>

          <ListItem button onClick={() => history.push(`/${MARKETING}`)}>
            <ListItemIcon>
              <BrushIcon />
            </ListItemIcon>
            <ListItemText className={classes.fontFaceSt} primary="Materiais" />
          </ListItem>

          {isAdmin() && (
            <ListItem button onClick={() => history.push(`/${MARKETING_POST}`)}>
              <ListItemIcon>
                <AddPhotoAlternateIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.fontFaceSt}
                primary="Postar Material"
              />
            </ListItem>
          )}

          {/* ----------------------------------------SOLICITAR MATERIAL------------------------------------------------------- */}
          <Paper elevation={5} className={classes.topicBorder}>
            <Typography
              variant="h5"
              className={(classes.fontFaceNP, classes.leterring)}
            >
              Solicitar Material
            </Typography>
          </Paper>

          {isAdmin() && (
            <ListItem button onClick={() => history.push(`/${REQUESTS}`)}>
              <ListItemIcon>
                <PhotoLibraryIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.fontFaceSt}
                primary="Solicitações"
              />
            </ListItem>
          )}
          <ListItem button onClick={() => history.push(`/${REQUEST_POST}`)}>
            <ListItemIcon>
              <AddPhotoAlternateIcon />
            </ListItemIcon>
            <ListItemText
              className={(classes.fontFaceSt, classes.solicitarMaterial)}
              primary="Solicitar Material"
            />
          </ListItem>
        </Box>
        {/*--------------------------------------------------Creditas--------------------------------------------------*/}
        <Paper elevation={5} className={classes.topicBorder}>
          <Typography
            variant="h5"
            className={(classes.fontFaceNP, classes.leterring)}
          >
            Creditas
          </Typography>
        </Paper>
        (
        <>
          <ListItem button onClick={() => history.push(`/proposal`)}>
            <ListItemIcon>
              <KitchenIcon />{" "}
            </ListItemIcon>
            <ListItemText
              className={classes.fontFaceSt}
              primary="Suas Propostas"
            />
          </ListItem>
          <ListItem button onClick={() => history.push(`/proposal/create`)}>
            <ListItemIcon>
              <FormatAlignJustifyIcon />{" "}
            </ListItemIcon>
            <ListItemText
              className={classes.fontFaceSt}
              primary="Criar Proposta"
            />
          </ListItem>
        </>
        )
        {/* --------------------------------------------------AgilPay------------------------------------------------ */}
        <Paper elevation={5} className={classes.topicBorder}>
          <Typography
            variant="h5"
            className={(classes.fontFaceNP, classes.leterring)}
          >
            AgilPay
          </Typography>
        </Paper>
        <ListItem button onClick={() => history.push(`/tabelas`)}>
          <ListItemIcon>
            <CreditCardIcon />{" "}
          </ListItemIcon>
          <ListItemText className={classes.fontFaceSt} primary="Tabelas" />
        </ListItem>
        <ListItem button onClick={() => history.push(`/agilpay/proposal`)}>
          <ListItemIcon>
            <RedeemIcon />{" "}
          </ListItemIcon>
          <ListItemText
            className={classes.fontFaceSt}
            primary="Solicitar Maquininhas"
          />
        </ListItem>
        {isAdmin() && (
          <ListItem button onClick={() => history.push(`/agilpay/proposals`)}>
            <ListItemIcon>
              <FormatAlignJustifyIcon />{" "}
            </ListItemIcon>
            <ListItemText
              className={classes.fontFaceSt}
              primary="Solicitações"
            />
          </ListItem>
        )}
        {/*--------------------------------------------------Ouvidoria--------------------------------------------------*/}
        <Paper elevation={5} className={classes.topicBorder}>
          <Typography
            variant="h5"
            className={(classes.fontFaceNP, classes.leterring)}
          >
            Ouvidoria
          </Typography>
        </Paper>
        <ListItem button onClick={() => history.push(`/${PROFILE}/feedback`)}>
          <ListItemIcon>
            <PhoneRoundedIcon />{" "}
          </ListItemIcon>
          <ListItemText className={classes.fontFaceSt} primary="Contato" />
        </ListItem>
      </List>
    </div>
  );
  /* ---------------------------------------ENTER SIDEBAR-------------------------------------------------------------------- */
  return (
    <div style={{ marginLeft: "30px", marginRight: "375px" }}>
      <React.Fragment key={"left"}>
        <IconButton
          style={{ color: "#00d2c6" }}
          edge="start"
          onClick={toggleDrawer("left", true)}
          color="default"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
