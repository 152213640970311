export default [
    // {
    //   message: `Pedimos desculpas pelo imprevisto. Mas por questões pessoais a Live de hoje com o Diretor Gilnei Moraes, foi transferida para QUARTA-FEIRA 01/09 às 10h`,
    //   link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGNiYThjODYtMjU0Yy00ZmQ5LWI5Y2UtN2YwNjZiYThmNjI2%40thread.v2/0?context=%7b%22Tid%22%3a%2229890b9f-e479-4c49-b32c-4845d9ceab08%22%2c%22Oid%22%3a%2265b0ae38-8763-4a6c-9d50-f84641b6d9c9%22%7d'
    // },
    // {
    //   message: `🕑 QUINTA-FEIRA – 02/09/2021 ✅ online: 09:30hs às 11:00hs – Live Seguros`,
    //   link: 'https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTQyY2RlM2YtNWI0NC00NTYzLWE4YzItOTJlODE2YTA1Y2E2%40thread.v2/0?context=%7b%22Tid%22%3a%2229890b9f-e479-4c49-b32c-4845d9ceab08%22%2c%22Oid%22%3a%2265b0ae38-8763-4a6c-9d50-f84641b6d9c9%22%7d'
    // }
    // {
    //   message: "Live com o diretor Gilnei Moraes. 10:00hrs Dia 02/08",
    //   color: "pink",
    // },
    // {
    //   message: "Live com o diretor Gilnei Moraes. 10:00hrs Dia 02/08",
    //   color: "pink",
    // },
    // {
    //   message: "Live com o diretor Gilnei Moraes. 10:00hrs Dia 02/08",
    //   color: "yellow",
    // }
  ];