/// How to add Action:
/// 1 - create action here
/// 1.5 - create api entry point if necessary
/// 2 - create const with dispatch type in ../constants folder
/// 3 - create redux in ../reducers folder to pass data in real time to all application
import * as api from '../api'

//Actions Creators
import {FETCH_ALL_AVISOS, FETCH_AVISO, DELETE_AVISO, UPDATE_AVISO } from "../constants/actionTypes";
import {START_LOADING, END_LOADING} from '../constants/actionTypes'

// export const getAviso = (id) => async (dispatch) => {
//     try{
//         dispatch({type:START_LOADING});
//         const {data} = await api.fetchAviso(id);
//         dispatch({type: FETCH_AVISO, payload: data});
//         dispatch({type: END_LOADING});
//     }catch (e) {
//         console.log(e);
//     }
// }

export const getAvisos = () => async (dispatch) => {
    try{
        const {data} =  await api.fetchAvisos();
        dispatch({type: FETCH_ALL_AVISOS, payload: data});
    }catch (e) {
        console.log(e);
    }
}

export const createAviso = (post, history) => async (dispatch) =>{
    try{
        const { data } = await api.createAviso(post);
        alert("Solicitação efetuada com sucesso");
        history.push(`/`);
    }catch (e) {
        alert("Ocorreu um erro durante a solicitação do Aviso");
        console.log(e);
        history.push('/');
    }
}

export const deleteAviso = (id) => async (dispatch) => {
    try{
        await api.deleteAviso(id);
        dispatch({type: DELETE_AVISO, payload: id});
    }catch (e) {
        alert("Ocorreu um erro durante a solicitação do Aviso");
        console.log(e);
    }
}