import React from "react";
import { Typography, Box } from "@material-ui/core";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Delete from '@material-ui/icons/Delete'
import {isAdmin} from '../../../actions/auth'

const Legenda = (props) => {
  const classes = useStyles();
  return (
    
    <Box display="flex" style={{padding:"5px"}} alignItems="center" boxShadow={4} className={classes.box}>
      {isAdmin() ? <Delete className={classes.icon}/> : <FiberManualRecordIcon style={{color: props.color}} className={classes.icon}/>}
      <Typography fontFamily="NoirPro" lineHeight={1.2} style={{padding:"3px", paddingLeft:"10px"}} component="div" variant="subtitle1">{props.link!=="" ? <a href={props.link}>{props.message}</a> : props.message}</Typography>
    </Box>
  );
};

export default Legenda;
