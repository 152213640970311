import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  TextField,
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { fetchProposals } from "../../../actions/creditas";
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import { HomeTwoTone, DriveEtaTwoTone } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";


function ConfirmationDialog(props) {
  const { onClose, selectedValue, open, text, actions } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  return null;
  // return (
  //   <Dialog
  //       open={open}
  //       onClose={handleClose}
  //       aria-labelledby="alert-dialog-title"
  //       aria-describedby="alert-dialog-description"
  //     >
  //       <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
  //       <DialogContent>
  //         <DialogContentText id="alert-dialog-description">
  //           {text}
  //         </DialogContentText>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={handleClose} color="primary">
  //           {actions.cancel.text}
  //         </Button>
  //         <Button onClick={handleClose} color="primary" autoFocus>
  //           {actions.ok.text}
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  // );
}


export default function Proposals() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { proposals } = useSelector((state) => state.proposals);
  const [proposalsView, setProposalsView] = useState(proposals); // visualização das propostas na lista.
  const [open, setOpen] = React.useState(false); // dialog de confirmação de exclusão.
  useEffect(() => {
    dispatch(fetchProposals());
  }, [dispatch]);
  useEffect(() => {
    setProposalsView(proposals);
  }, [proposals]);
  const handleFilter = (e) => { //search by name
    if (e.target.value === "") setProposalsView(proposals);
    setProposalsView(
      proposals.filter((proposal) =>
        proposal.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        proposal.cpf.includes(e.target.value.toLowerCase())
      )
    );
  };

  const translateStatus = (proposal) => {
    switch (proposal.status) {
      case "PENDING":
        return "Pendente";
      case "REJECTED":
        return "Rejeitado";
      case "ONGOING":
        return "Em andamento";
      case "PAID":
        return "Pago";
      default:
        console.log(proposal)
        return "Desconhecido";
    }
  }

  const translateType = (proposal) => {
    switch (proposal.productType) {
      case "HOME_REFI":
        return <HomeTwoTone/>;
      case "AUTO_REFI":
        return <DriveEtaTwoTone />;
      default:
        return "Desconhecido";
    }
  }

  const translateFeed = (description) => {
    switch (description) {
      case "OUT_OF_POLICY":
        return "Fora da política";
      case "OUT_OF_POLICY_COLLATERAL":
        return "Fora da política de inspeção";
      case "OUT_OF_POLICY_CREDIT":
        return "Fora da política de crédito";
      case "OUT_OF_POLICY_VEHICLE_MODEL":
        return "Fora da política de modelo de veículo";
      case "OUT_OF_POLICY_VEHICLE_YEAR":
        return "Fora da política de ano do veículo";
      case "DROP_OUT":
        return "Desistência";
      case "CLOSED_WITH_COMPETITOR":
        return "Sem Interesse / Fechou com outro competidor";
      case "CONTACT_WITHOUT_SUCCESS":
        return "Contato sem sucesso";
      default:
        console.log(description)
        return "Desconhecido";
    }
  }

  function last(feeds){
    // if feed isn't empty return last feed
    const length = feeds.length;
    if(length > 0) return feeds[length-1]
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Paper>
      <Box display="flex" flexDirection="row">
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          onChange={handleFilter}
        />
        <IconButton onClick={() => history.push("/proposal/create")}>
          <AddIcon />
        </IconButton>
      </Box>
      <List>
        {proposalsView.map((proposal) => {
          const lastFeed = last(proposal.feeds)
          return (<ListItem key={proposal.id} button>
            <Box width='60px'>
              {translateType(proposal)}
            </Box>
            <ListItemText
              
              primary={`${proposal.name} - ${proposal.cpf}`}
              secondary={(<Typography style={proposal.status === "REJECTED" ? {color: "red"} : {}}>{`${translateStatus(proposal)} ${lastFeed ? "- "+translateFeed(lastFeed.description) : ""}`}</Typography>)}
            />
            <ListItemSecondaryAction>
              <IconButton disabled>
                <DescriptionIcon />
              </IconButton>
              <IconButton onClick={handleClickOpen}>
                <DeleteIcon/>
              </IconButton>
              <ConfirmationDialog open={open} onClose={handleClose} />
            </ListItemSecondaryAction>
          </ListItem>
          )})}
      </List>
    </Paper>
  );
}
