import React, { useState, useRef } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { TextField, Button, Typography, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";

import useStyles from "./styles";

const Form = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("profile"));
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [error, setError] = useState({
    title: null,
    message: null,
    telefone: null,
  });
  const [postData, setPostData] = useState({
    title: "",
    message: "",
    telefone: localStorage.getItem("telefone")?.replace(/"/g, "") || "",
    type: "Alteração",
    selectedFile: [],
  });
  const dispatch = useDispatch();

  const handleValidation = () => {
    const errorRef = {
      title: null,
      message: null,
      telefone: null,
    };
    if (postData.title.length === 0)
      errorRef.title = "Seu Título não pode estar vazio.";
    if (postData.title.length > 50)
      errorRef.title = "Seu Título deve conter no máximo 50 carecteres.";
    if (postData.message.length === 0)
      errorRef.message = "Por favor descreva seu pedido.";
    if (postData.telefone.length === 0)
      errorRef.telefone = "Coloque seu número de celular.";

    setError(errorRef);

    if (errorRef.title || errorRef.message || errorRef.telefone)
      throw new Error("Campo Invalido");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("telefone", JSON.stringify(postData.telefone));
    try {
      if (sending) alert("Aguarde, estamos enviando o pedido.");
      else {
        handleValidation();
        const data = new FormData();
        data.set("title", postData.title);
        data.set("text", postData.message);
        data.set("telefone", postData.telefone);
        data.set("type", postData.type);
        data.set("user", user?.result?.id);
        const files = postData.selectedFile;
        for (var i = 0; i < files?.length; i++) {
          var file = files[i];
          data.append("selectedFile", file);
        }
        dispatch();
        // createRequest(data, history)
        setSending(true);
      }
    } catch (error) {
      alert(error);
      setSending(false);
    }
    // clear();
  };

  if (!user?.result?.name) {
    return (
      <Paper className={classes.paper} elevation={6}>
        <Typography variant="h6" align="center">
          Por favor entre na sua conta para fazer solicitações.
        </Typography>
      </Paper>
    );
  }
  const clear = () => {
    setError({
      title: null,
      message: null,
      telefone: null,
    });
    setPostData({
      title: "",
      message: "",
      telefone: "",
      selectedFile: [],
    });
  };

  const showFileNames = () => {
    let text = "";
    for (const file of postData.selectedFile) {
      text += file.name + " ";
    }
    return (
      <Typography align="justify" variant="subtitle2">
        {text}
      </Typography>
    );
  };

  return (
    <Paper className={classes.paper}>
      <Alert severity="warning">
        A Solicitação tem um prazo de 240 horas úteis
      </Alert>
      <Alert severity="info">Limite de 5 solicitações por dia</Alert>

      <form
        autoComplete="off"
        noValidate
        className={`${classes.root} ${classes.form}`}
        lg={12}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Typography className={classes.fontFaceNP} variant="h5">
          Solicite um Material
        </Typography>
        <TextField
          required
          error={error.title ? true : false}
          name="title"
          variant="outlined"
          label={error.title ? error.title : "Título"}
          fullWidth
          value={postData.title}
          onChange={(e) => setPostData({ ...postData, title: e.target.value })}
        />
        <TextField
          required
          error={error.message ? true : false}
          name="message"
          variant="outlined"
          multiline
          rows={4}
          label={error.message ? error.message : "Descrição"}
          fullWidth
          value={postData.message}
          onChange={(e) =>
            setPostData({ ...postData, message: e.target.value })
          }
        />
        <TextField
          required
          error={error.telefone ? true : false}
          name="telefone"
          variant="outlined"
          label={error.telefone ? error.telefone : "Telefone"}
          fullWidth
          value={postData.telefone}
          onChange={(e) =>
            setPostData({ ...postData, telefone: e.target.value })
          }
        />
        <Grid direction="row" container spacing={6} justify="center">
          <Grid item className={classes.tipoDeMaterial}>
            <InputLabel id="demo-simple-select-helper-label">Tipo</InputLabel>
            <NativeSelect
              value={postData.type}
              onChange={(e) =>
                setPostData({ ...postData, type: e.target.value })
              }
              inputProps={{
                name: "type",
                id: "type-native-helper",
              }}
            >
              <option value={"Alteração"}>Alteração em Material</option>
              <option value={"Apresentações"}>Apresentações</option>
              <option value={"Assinatura"}>Assinatura de e-mail</option>
              <option value={"Banner"}>Banner</option>
              <option value={"Brochuras"}>Brochuras</option>
              <option value={"Campanhas"}>Campanhas</option>
              <option value={"Capa Facebook"}>Capa Facebook</option>
              <option value={"Cartaz"}>Cartaz</option>
              <option value={"Cartão de visitas"}>Cartão de visitas</option>
              <option value={"Cartão virtual"}>Cartão virtual</option>
              <option value={"Comunicados"}>Comunicados</option>
              <option value={"Convite"}>Convite</option>
              <option value={"Cracha"}>Cracha</option>
              <option value={"Folder"}>Folder</option>
              <option value={"Logotipo"}>Logotipo</option>
              <option value={"Outdoor"}>Outdoor</option>
              <option value={"Panfletos"}>Panfletos</option>
              <option value={"Personalização"}>Personalização</option>
              <option value={"Post para redes sociais"}>
                Post para redes sociais
              </option>
              <option value={"Projeto de Fachada"}>Projeto de Fachada</option>
              <option value={"Quadros Lojas"}>Quadros Lojas</option>
              <option value={"Script"}>Script</option>
              <option value={"Textos"}>Textos</option>
              <option value={"Outros"}>Outros</option>
            </NativeSelect>
          </Grid>
          <Grid item>
            <Button
              style={{ backgroundColor: "grey", color: "white" }}
              variant="contained"
              component="label"
            >
              Imagens de Referencia
              <input
                multiple={true}
                type="file"
                hidden
                onChange={(e) =>
                  setPostData({ ...postData, selectedFile: e.target.files })
                }
              />
            </Button>
            <div>{postData.selectedFile?.length > 0 && showFileNames()}</div>
          </Grid>
        </Grid>
        <Button
          className={classes.buttonSubmit}
          style={
            !sending
              ? { width: 100, backgroundColor: "#00d2c6" }
              : { width: 100, backgroundColor: "grey" }
          }
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          fullWidth
        >
          Enviar
        </Button>
        <Button
          className={classes.buttonSubmit}
          style={{ width: 100, backgroundColor: "rgba(10, 150, 255, 0.7)" }}
          variant="contained"
          color="secondary"
          size="small"
          onClick={clear}
          fullWidth
        >
          Limpar
        </Button>
      </form>
    </Paper>
  );
};

export default Form;
