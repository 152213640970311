import { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./styles";

const Popup = ({ startsOpen, handleClose = () => {}, children }) => {
    const [open, setOpen] = useState(startsOpen);
    const fullScreen = useMediaQuery("(max-width: 600px)");
    const classes = useStyles();

    const handleCloseInternal = () => {
        handleClose();
        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={handleCloseInternal} scroll="body" fullScreen={fullScreen}>
        <div id="close-wrapper" className={classes.closeButtonWrapper}>
            <IconButton onClick={handleCloseInternal}>
                <CloseRounded />
            </IconButton>
        </div>
        <DialogContent>{children}</DialogContent>
        </Dialog>
    );
}

export default Popup;