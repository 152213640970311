import * as api from '../api'
import {FETCH_AGILPAY_REQUESTS} from "../constants/actionTypes";



export const createRequest = (request, history, setSending) => async (dispatch)=>{
    try{
        const {data} = await api.createPedidoMaquina(request)
        alert("Pedido de maquina criado com sucesso!")
        history.push('/');
    }catch (e) {
        console.log(e.response.data);
        alert(e.response.data.message);
        setSending(false);
    }
}

export const getRequests = () => async (dispatch)=>{
    try{
        const {data} = await api.getPedidosMaquina()
        dispatch({type: FETCH_AGILPAY_REQUESTS, payload: data})
    }catch (e) {
        console.log(e.response.data);
        alert(e.response.data.message);
    }
}

export const completeRequest = (id, history) => async (dispatch)=>{
    try{
        await api.completePedidoMaquina(id)
        history.go(0)
    }catch (e) {
        console.log(e.response.data);
        alert(e.response.data.message);
    }
}
