import { FETCH_AGILPAY_REQUESTS } from "../constants/actionTypes";
export const agilpay = (state = {requests: [], completes: [] }, action) => {
  switch (action.type) {
    case FETCH_AGILPAY_REQUESTS:
      var completes = [],
        requests = [];
      for (const request of action.payload) {
        if (request.complete) {
          completes.push(request);
        } else {
          requests.push(request);
        }
      }
      return {
        ...state,
        requests: requests,
        completes: completes,
      };
    default:
      return state;
  }
};
