import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
card: {
  width: "40%",
  marginTop: "12%",
  margin: "auto",
  backgroundColor: "white",
  borderRadius: "5px"
},

buttons: {
   paddingTop: "80px",
   paddingBottom: "30px",
   padding: "20px"
},


}));