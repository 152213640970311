// import React, { useState, useEffect } from "react";
// import {
//   TextField,
//   Button,
//   Typography,
//   CircularProgress,
//   Paper,
// } from "@material-ui/core";
// import { useDispatch, useSelector } from "react-redux";
// import {fetchProposal} from "../../../../actions/proposals";

const CreditasDetails = () => {
    // const proposta = useSelector(state => state.proposal);
    // const dispatch = useDispatch();
    // const { id } = useParams();
    
    // useEffect(() => {
    //     dispatch(fetchProposal(id));
    // }
    // , [proposta.id]);

    // return proposta?.id != id ? (<Paper>
    //     <CircularProgress />
    // </Paper>) :
    // (<Paper>
    //     <Typography variant="h5">
    //         {proposta.id}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.legacyId}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.productType}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.cpf}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.name}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.status}
    //     </Typography>
    //     <Typography variant="h5">   
    //         {proposta.steps}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.feeds}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.creditAnalysis}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.payment}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.createAt}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.creator}
    //     </Typography>
    //     <Typography variant="h5">
    //         {proposta.updatedAt}
    //     </Typography>
    // </Paper>)
}

export default CreditasDetails;