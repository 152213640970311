import {
    Paper,
    Typography,
    Button
} from '@material-ui/core';
import { useEffect } from 'react';
import useStyles from "./styles";
import { getAvisos } from '../../actions/avisos'
import { useDispatch, useSelector } from 'react-redux';

const Admin = () => {
    const dispatch = useDispatch()
    const {avisos} = useSelector(state => state.avisos)

    useEffect(() => {
        dispatch(getAvisos())
    }, []);
    return (
        <Paper className={useStyles.paper}>
            <Typography variant="h6" component="h1">Test</Typography>
        </Paper>
    );
}
export default Admin;