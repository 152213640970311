import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  listContent: {
    width: 'auto',
    height: "100%",
    color: "#393E46",


  },

  fontFaceNP: {
    fontFamily: "NoirPro",
  },

  divider: {
    marginTop: "30px",
    size: "10px",
  },

  fontFaceSt: {
    marginTop: "2%",
  },
  // ------------------------------------------Perfil------------------------------------------

  config: {
    textAlign: "center",
  },


  perfilIcon: {
    marginTop: "0px",
    marginBottom: "0px",
    margin: "auto",
    fontSize: "30px",
    height: "55px",
    width: "55px",
  },

  userName: {
    textAlign: "center",
    fontSize: "20px",
    marginTop: "-10px",
    marginBottom: "0px",
    margin: "auto",

  },

  userNameList: {
    "&:hover": {
      backgroundColor: "white",
    }
  },

  borderPerfil: {
    alignContent: "center",
    marginTop: "25px",
    width: "100%",
    margin: "auto",
  },

  // ------------------------------------------Marketing------------------------------------------
  leterring: {
    color: "#00d2c6",
    textAlign: "center",
    fontSize: "20px"
  },

  topicBorder: {
    marginTop: "25px",
    marginBottom: "25px",
    borderRadius: "3px",
    width: 'auto',
    margin: "auto",
  },

  // --------------------------------------Solicitar Material------------------------------------------
  material: {
    textAlign: "center",
    color: "#00d2c6",
  },
}));
