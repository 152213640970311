import React, { useState } from "react";
import {
  Avatar,
  Paper,
  Grid,
  Typography,
  Container,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AlertCustom } from "../Alert";
import { triggerAlert } from "../../actions/alert";

import useStyles from "./styles";
import Input from "./Input";
import { signin, signup, isAdmin } from "../../actions/auth";

const inititalFormState = {
  name: "",
  codigo: "",
  email: "",
  password: "",
  confirmPassword: "",
  brand: "",
};




const Auth = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(inititalFormState);
  const [logo, setLogo] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [isSignup, changeIsSignup] = useState(isAdmin() ? true : false);
  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);
  const [isFranqueado, setIsFranqueado] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (isSignup) {
        const data = new FormData()
        Object.entries(formData).forEach(([key, value]) => data.append(key, value))
        if (sending){
          return dispatch(
            triggerAlert(
              "Aguarde um momento, estamos processando seu cadastro.",
              "info"
            )
          )
        }
        data.append('isFranqueado', isFranqueado)
        if(!isFranqueado) {
          data.append('selectedFile', logo)
          dispatch(signup(data, history));
        }else dispatch(signup(data, history));
        setSending(true);
      } else {
        dispatch(signin({...formData, isFranqueado: isFranqueado}, history));
      }
    } catch (e) {
      dispatch(
        triggerAlert(e.message, "error")
      )
      setSending(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogo = (file) => {
    if (file && file.size < 2097152) {
      setLogo(file);
      dispatch(
        triggerAlert("Logo foi Alterado com sucesso, pronto para envio.", "success")
      )
    } else {
      dispatch(
        triggerAlert("Tamanho do Logo deve ser menor que 2MB.", "error")
      )
    }
  };

  // const switchMode = () => {
  //   changeIsSignup((currentStatus) => !currentStatus);
  //   setShowPassword(false);
  // };

  return (
    <Container component="main" maxWidth="xs">
      <AlertCustom/>
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">
          {isSignup ? "Registrar" : "Entrar"}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {isSignup && (
              <>
                <Input
                  name="name"
                  label="Nome Completo"
                  handleChange={handleChange}
                  autoFocus
                />
                <Input
                  name="codigo"
                  label="Codigo de Franquia"
                  handleChange={handleChange}
                  type="text"
                />
              </>
            )}
            <Input
              name="email"
              label="Endereço de Email"
              handleChange={handleChange}
              type="email"
            />
            <Input
              name="password"
              label="Senha"
              handleChange={handleChange}
              type={showPassword ? "text" : "password"}
              handleShowPassword={handleShowPassword}
            />
            {isSignup && (
              <Input
                name="confirmPassword"
                label="Repita a Senha"
                handleChange={handleChange}
                type="password"
              />
            )}
            {isSignup && (<Grid container spacing={4}>
            <Grid item>
            <FormGroup className={classes.checkbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFranqueado}
                    onChange={() => setIsFranqueado(!isFranqueado)}
                  />
                }
                label="É Franqueado?"
              />
            </FormGroup>
            </Grid>
            <Grid item>
            {isSignup && !isFranqueado && (
                <Button
                  className={classes.buttons}
                  variant="text"
                  fullWidth
                  color="primary"
                  component="label"
                >
                  Logo da Parceira
                  <input
                    multiple={false}
                    type="file"
                    hidden
                    onChange={(e) => handleLogo(e.target.files[0])}
                  />
                </Button>
            )}
            {logo && (<Typography variant="h6">{logo.name}</Typography>)}
            </Grid>
            </Grid>)}
            {isSignup && !isFranqueado && (
              <Input name="brand" label="Nome da Parceira" handleChange={handleChange} />
            )}
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isSignup ? "Registrar" : "Entrar"}
          </Button>
          {/* <Grid container justify="flex-end">
                      <Grid item>
                          <Button onClick={switchMode}>
                            {isSignup ? "Já tem uma conta? Entre" :  "Não tem uma conta? Registre-se"}
                          </Button>
                      </Grid>
                    </Grid> */}
        </form>
      </Paper>
    </Container>
  );
};

export default Auth;
