import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import {
  TextField,
  Button,
  Typography,
  Paper,
  FormControl,
  Box,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";
import Maquininha from "../../../../images/maquininha.jpeg";

import useStyles from "./styles";
import { createRequest } from "../../../../actions/agilpay";

const CheckboxWithTextField = (props) => {
  const classes = props.classes;
  return (
    <FormControl className={classes.checkboxInput}>
        <Checkbox
          checked={props.checked}
          onChange={props.onChange}
          name={props.name}
          color="primary"
        />
    <TextField
      disabled={!props.checked}
      className={classes.textField}
      label={`${props.label}*`}
      name={props.name}
      value={props.value}
      onChange={props.quantityChange}
      margin="dense"
      fullWidth
      helperText={"Somente valores Numericos"}
    />
    </FormControl>
  );
};

const initialState = {
  numero: "",
  address: {
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    cep: "",
  },
  telefone: localStorage.getItem("telefone")?.replace(/"/g, "") || "",
  payment:{
    method: "Pix",
    term: "À Vista",
  }
};

const MaquininhaRequest = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("profile"));
  const history = useHistory();
  const [checkboxes, setCheckboxes] = useState({agilzinha: {checked: false, quantity: ""}, agilpay: {checked: false, quantity: ""}});
  const [sending, setSending] = useState(false);
  const [postData, setPostData] = useState(initialState);
  const dispatch = useDispatch();


  const handleSubmit = () => {
    localStorage.setItem("telefone", JSON.stringify(postData.telefone));
    try {
      if (sending) alert("Aguarde, estamos enviando o pedido.");
      else {
        //handleValidation();
        const request = postData;
        var quantity1 = 0;
        var quantity2 = 0;
        if(checkboxes.agilzinha.checked) quantity1 = Number(checkboxes.agilzinha.quantity);
        if(checkboxes.agilpay.checked) quantity2 = Number(checkboxes.agilpay.quantity);
        if(quantity1 + quantity2 < 5) {
          throw new Error("A quantidade mínima necessaria de maquininhas é de 5");
        }
        request.agilzinha = quantity1
        request.agilpay = quantity2
        dispatch(createRequest(request, history, setSending));
        setSending(true);
      }
    } catch (error) {
      alert(error);
      setSending(false);
    }
    // clear();
  };

  if (!user?.result?.name) {
    return (
      <Paper className={classes.paper} elevation={6}>
        <Typography variant="h6" align="center">
          Por favor entre na sua conta para fazer solicitações.
        </Typography>
      </Paper>
    );
  }
  const clear = () => {
    setPostData(initialState);
  };
  return (
    <Paper className={classes.paper}>
      <form
        className={`${classes.root} ${classes.form}`}
        noValidate
        lg={12}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <img
          style={{ borderRadius: "4px", width: "100%", marginBottom: "10px" }}
          src={Maquininha}
          alt="maquininha"
          className={classes.maquininha}
        />
        <Typography variant="subtitle1" align="center" style={{ color: "gray" }}>
          * Campos Obrigatórios
        </Typography>
        <Box width="100%"/>
        <Typography className={classes.fontFaceNP} variant="h6" align="center">
          Informe a quantidade de Maquinas:
        </Typography>
        <Typography variant="subtitle1" align="center" style={{ color: "gray" }}>
        É necessario pedir pelo menos 5 maquininhas.
        </Typography>
        <Box width="100%" alignItems="center">
        <CheckboxWithTextField classes={classes} label="Agilzinha" name="agilzinha" checked={checkboxes.agilzinha.checked} onChange={(e) => {
          setCheckboxes({...checkboxes, agilzinha: {checked: e.target.checked, quantity: checkboxes.agilzinha.quantity}});
        }} quantityChange={(e)=>{
          setCheckboxes({...checkboxes, agilzinha: {checked: checkboxes.agilzinha.checked, quantity: e.target.value}});
        }} value={checkboxes.agilzinha.quantity} />
        <CheckboxWithTextField classes={classes} label="AgilPay" name="agilpay" checked={checkboxes.agilpay.checked} onChange={(e) => {
          setCheckboxes({...checkboxes, agilpay: {checked: e.target.checked, quantity: checkboxes.agilpay.quantity}});
        }} quantityChange={(e)=>{
          setCheckboxes({...checkboxes, agilpay: {checked: checkboxes.agilpay.checked, quantity: e.target.value}});
        }} value={checkboxes.agilpay.quantity} />
        </Box>
        <Typography className={classes.fontFaceNP} variant="h6" align="center">
          Informações do Pedido:
        </Typography>
      
        <Box className={classes.field}>
          <TextField
            required
            name="cidade"
            variant="outlined"
            label={"Cidade"}
            value={postData.address.city}
            onChange={(e) =>
              setPostData({ ...postData, address: {...postData.address, city: e.target.value} })
            }
          />
          <TextField
            required
            name="estado"
            variant="outlined"
            label={"Estado"}
            value={postData.address.state}
            onChange={(e) =>
              setPostData({ ...postData, address: {...postData.address, state: e.target.value} })
            }
          />
          <TextField
            required
            name="cep"
            variant="outlined"
            label={"CEP"}
            value={postData.address.cep}
            onChange={(e) =>
              setPostData({ ...postData, address: {...postData.address, cep: e.target.value} })
            }
          />
        </Box>
        <TextField
            required
            fullWidth
            name="rua"
            variant="outlined"
            label={"Rua"}
            value={postData.address.street}
            onChange={(e) =>
              setPostData({ ...postData, address: {...postData.address, street: e.target.value} })
            }
          />
        <Box className={classes.field}>
          
          <TextField
            required
            name="bairro"
            variant="outlined"
            label={"Bairro"}
            value={postData.address.neighborhood}
            onChange={(e) =>
              setPostData({ ...postData, address: {...postData.address, neighborhood: e.target.value} })
            }
          />
          <TextField
            required
            name="numero"
            variant="outlined"
            label={"Número"}
            style={{ maxWidth: "100px" }}
            value={postData.address.number}
            onChange={(e) =>
              setPostData({ ...postData, address: {...postData.address, number: e.target.value} })
            }
          />
                    <TextField
          
          name="complemento"
          variant="outlined"
          label="Complemento"
          value={postData.address.complement}
          onChange={(e) =>
            setPostData({ ...postData, address: {...postData.address, complement: e.target.value} })
          }
          />
        </Box>
          <TextField
          required
          name="telefone"
          variant="outlined"
          label={"Telefone"}
          
          value={postData.telefone}
          onChange={(e) =>
            setPostData({ ...postData, telefone: e.target.value })
          }
        />
        <FormControl className={classes.select}>
          <InputLabel id="demo-simple-select-helper-label">
            Metodo de Pagamento
          </InputLabel>
          <NativeSelect
            value={postData.payment.method}
            onChange={(e) =>
              setPostData({ ...postData, payment: {...postData.payment, method: e.target.value} })
            }
            inputProps={{
              name: "type",
              id: "type-native-helper",
            }}
          >
            <option  value={"Pix"}>Pix </option>
            <option  value={"Cartão"}>Cartão</option>
          </NativeSelect>
        </FormControl>
        <FormControl className={classes.select}>
          <InputLabel id="demo-simple-select-helper-label">
            Parcelamento
          </InputLabel>
          <Select
            disabled={postData.payment.method !== "Cartão"}
            value={postData.payment.term}
            onChange={(e) => { 
              setPostData({ ...postData, payment: {...postData.payment, term: e.target.value} })
            }}
          >
            <MenuItem  value={"À Vista"}>A Vista</MenuItem>
            <MenuItem  value={"2x"}>2x</MenuItem>
            <MenuItem  value={"3x"}>3x</MenuItem>
            <MenuItem  value={"4x"}>4x</MenuItem>
            <MenuItem  value={"5x"}>5x</MenuItem>
            <MenuItem  value={"6x"}>6x</MenuItem>
            <MenuItem  value={"7x"}>7x</MenuItem>
            <MenuItem  value={"8x"}>8x</MenuItem>
            <MenuItem  value={"9x"}>9x</MenuItem>
            <MenuItem  value={"10x"}>10x</MenuItem>
          </Select>
        </FormControl>
        <Box width="100%"/>
        <Button
          className={classes.buttonSubmit}
          style={
            !sending
              ? { width: 100, backgroundColor: "#00d2c6" }
              : { width: 100, backgroundColor: "grey" }
          }
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
        >
          Enviar
        </Button>
        <Button
          className={classes.buttonSubmit}
          style={{ width: 100, backgroundColor: "rgba(10, 150, 255, 0.7)" }}
          variant="contained"
          color="secondary"
          size="large"
          onClick={clear}
          fullWidth
        >
          Limpar
        </Button>
      </form>
    </Paper>
  );
};

export default MaquininhaRequest;
