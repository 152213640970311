import {
    FETCH_ALL_REQUESTS,
    END_LOADING_REQUEST,
    START_LOADING_REQUEST,
    FETCH_REQUEST,
    DELETE_REQUEST,
    UPDATE_REQUEST,
  } from '../constants/actionTypes'
  export const requests = (state = { isLoading: true, requests: []}, action) => {
    switch (action.type) {
      case START_LOADING_REQUEST:
      return {
        ...state, isLoading: true
      };
    case END_LOADING_REQUEST:
      return {
        ...state, isLoading: false
      };
      case FETCH_REQUEST:
      return {
        ...state, request: action.payload
      };
      case FETCH_ALL_REQUESTS:
        return {
          ...state, requests: action.payload.data
        };
      case DELETE_REQUEST:
          return {...state, request: null, requests: state.requests.filter((request) => request._id !== action.payload)};
      case UPDATE_REQUEST:
      return {...state, request: action.payload,requests: state.requests.map((request) => request._id === action.payload._id ? action.payload : request)};
      default:
        return state;
    }
  }