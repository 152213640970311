import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    alignSelf: "end",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  message: {
    paddingBottom: 50,
    backgroundColor: "#ebebeb",
    display: "flex",
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  fileInput: {
    width: "100%",
    margin: "auto",
    marginTop: 20,
  },
  buttonSubmit: {
    marginTop: 20,
    marginBottom: 10,
    marginLeft: 6,
  },
  fontFaceNP: {
    fontFamily: "NoirPro",
  },

  tipoDeMaterial: {
    marginTop: 20,
    marginBottom: 10,
  },

  labelImage: {
    color: "gray",
  },
  selectWrapper: {
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
  },
  button: {
    backgroundColor: "#00d2c6",
  },
  formControl: {},
  outlined: {
    width: "100%",
    border: "1px solid",
    borderRadius: "3px",
    borderColor: "#959595",
    padding: "5px",
    fontSize: "1.0rem",
  },
  imagePicker: {
    margin: "10px",
  }
}));
