/// How to add Action:
/// 1 - create action here
/// 1.5 - create api entry point if necessary
/// 2 - create const with dispatch type in ../constants folder
/// 3 - create redux in ../reducers folder to pass data in real time to all application
import * as api from '../api'

//Actions Creators
import {START_LOADING_REQUEST, END_LOADING_REQUEST, FETCH_REQUEST, FETCH_ALL_REQUESTS, FETCH_BY_SEARCH, UPDATE_REQUEST, DELETE_REQUEST } from "../constants/actionTypes";
import { triggerAlert } from './alert';

export const getRequest = (id) => async (dispatch) => {
    try{
        dispatch({type:START_LOADING_REQUEST});
        const {data} = await api.fetchRequest(id);
        dispatch({type: FETCH_REQUEST, payload: data});
        dispatch({type: END_LOADING_REQUEST});
    }catch (e) {
        console.log(e);
    }
}

export const getRequests = (complete) => async (dispatch) => {
    try{
        dispatch({type:START_LOADING_REQUEST});
        const {data} =  await api.fetchRequests(complete);
        dispatch({type: FETCH_ALL_REQUESTS, payload: data});
        dispatch({type: END_LOADING_REQUEST});
    }catch (e) {
        console.log(e);
    }
}

export const getPostsBySearch = (searchQuery) => async (dispatch) => {
  try{
    dispatch({type:START_LOADING_REQUEST});
    const {data: { data }} = await api.fetchPostsBySearch(searchQuery);
    dispatch({type: FETCH_BY_SEARCH, payload: data});
    dispatch({type: END_LOADING_REQUEST});
  }catch(e){
    console.log(e);
  }
}

export const createRequest = (post, history, setSending) => async (dispatch) =>{
    try{
      dispatch({type:START_LOADING_REQUEST});
        const { data } = await api.createRequest(post);
        dispatch(triggerAlert("Solicitação enviada com sucesso!", "success"));
        history.push(`/`);
    }catch (e) {
        console.log(e);
        setSending(false);
        dispatch(triggerAlert(e.response.data.message, "error"));
        // alert(e.response.data.message);
        // history.push('/');
    }
}

// export const updatePost = (id, post, history) => async (dispatch) =>{
//     try{
//       dispatch({type:START_LOADING_REQUEST});
//         const {data} = await api.updatePost(id, post);
//         history.push(`/posts/${id}`);
//         dispatch({type:   UPDATE, payload: data});
//     }catch (e) {
//         console.log(e);
//     }
// }

export const deleteRequest = (id) => async (dispatch) => {
    try{
        await api.deleteRequest(id);

        dispatch({type: DELETE_REQUEST, payload: id});
    }catch (e) {
        console.log(e);
    }
}

export const completeRequest = (id, post, history) => async (dispatch) =>{
    try{
        const { data } = await api.completeRequest(id, post);
        history.push(`/requests`);
        //dispatch({type: UPDATE_REQUEST, payload: data});
    }catch (e) {
        console.log(e);
        dispatch(triggerAlert(e.response.data.message, "error"));
    }
}


export const fetchRequestFile = async (id) => {
    try{
        // return await fetch(`${api.API_URL}/user/file/${id}`).then((response) => {
        //     return response.blob();
        //   });
        const { data } = await api.fetchRequestFile(id);
        return data;
    }catch(e){
        console.log(e);
    }
  }


// export const getImages = async (id) => {
//     try{
//         const { data } = await api.fetchImages(id);
//         return data;
//     }catch(e){
//         // there is no error 99% of the time
//         //console.log(e);
//     }
//   }