import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },

  a: {
   background: "blue"
  },

  calendarLogin: {
   marginRight:"10px",
   marginTop: "45px",
   
  },

  recadoTitle: {
   margin: "auto",
   marginTop: "-38px",
   borderRadius: "8px",
   width: "auto",
   color: "#00d2c6",
  },

  avisos: {
   padding: "8px",
   borderRadius: "5px",
   backgroundColor: 'white',
   },

   cardContent: {
      flex: '1 0 auto',
      backgroundColor: "white"
   },

  noticia: {
   marginLeft: "20px",
   marginTop: "20px",
   height: "320px",
   border: "1px solid",
   color: "black",
   borderRadius: "5px",
  },

  avisoImg: {
    height: "auto", 
    paddingLeft: "20px",
    [theme.breakpoints.down('sm')]: {
     paddingRight: "20px",
    },

  },

  calendarImg: {
    padding: "0",
    height: "300px",
    width: "300px",
    [theme.breakpoints.down('sm')]: {
      height: "auto",
      width: "auto",
     },
  },

  modal: {
    width: "35%", 
    height: "auto", 
    margin: "auto", 
    marginTop: "10%",
    [theme.breakpoints.down('sm')]: {
      width: "auto"
    },
  },
}));
