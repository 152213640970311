//Actions Creators
import { SET_ALERT } from "../constants/actionTypes";

export const triggerAlert = (message, severity="success") => async (dispatch) => {
    dispatch(
        {
            type: SET_ALERT,
            payload: { message: message, severity: severity }
        }
    );
};
