import React from "react";
import {
  CardActions,
  CardMedia,
  Button,
  Typography,
  ButtonBase,
  Paper,
  Box,
} from "@material-ui/core";
import { flexbox } from '@material-ui/system';
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
//import agil logo from images
import loading from "../../../../images/loading.jpg"


import useStyles from "./styles";
import { deletePost, likePost, fetchBlob } from "../../../../actions/posts";
import { isAdmin } from "../../../../actions/auth"


const Post = ({ post }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [img, setImg] = React.useState(null);
  const user = JSON.parse(localStorage.getItem("profile"));

  const changeImg = async (id) => {
    try {
      const blob = await fetchBlob(id);
      const type = await blob.type.slice(0, blob.type.indexOf("/"));
      if (type === "image") {
        setImg(window.URL.createObjectURL(blob));
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  if (post.marketingFile[0] && img === null) changeImg(post.marketingFile[0], setImg);

  const Likes = () => {
    let postLikesLength = post.likes.length;
    if (postLikesLength > 0) {
      return post.likes.find(
        (like) => like === (user?.result?.googleId || user?.result?._id)
      ) ? (
        <>
          <ThumbUpAltIcon fontSize="small" />
          &nbsp;
          {postLikesLength > 2
            ? `Você e ${postLikesLength - 1} outros `
            : `${postLikesLength} Curtir${post.likes.lengh > 1 ? "s" : ""}`}
        </>
      ) : (
        <>
          <ThumbUpAltOutlined fontSize="small" />
          &nbsp;{postLikesLength} {postLikesLength === 1 ? "Curtir" : "Curtidas"}
        </>
      );
    }
    return (
      <>
        <ThumbUpAltOutlined fontSize="small" />
        &nbsp;Like
      </>
    );
  };
  const openPost = () => {
    history.push(`/posts/${post._id}`);
  };


  return (
    <Paper className={classes.card} elevation={10}>
      <ButtonBase className={classes.cardAction} onClick={openPost}>
        <CardMedia className={classes.media}
          image={img || loading} title={post.title} />
        {/* <Box className={classes.overlay}>
          <Typography variant="h6">{post.name}</Typography>
          <Typography variant="body2">
            {moment(post.createAt).fromNow()}
          </Typography>
        </Box> */}
        {(isAdmin()) && (
          <Box className={classes.overlay2} name="edit">
            <SettingsIcon
              fontSize="default"
              className={classes.edit}
              size="small"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/post/${post._id}`); //setCurrentId(post._id); //vindo como param
              }}
            />
            {/* <Typography
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/post/${post._id}`); //setCurrentId(post._id); //vindo como param
              }}
              size="small"
            >
              <MoreHorizIcon fontSize="default" />
            </Typography> */}
          </Box>
        )}
        <Box className={classes.details}>
          <Typography noWrap={true} variant="body2" color="textSecondary">
            {post.tags?.map((tag) => `#${tag} `)}
          </Typography>
        </Box>
        <Typography noWrap={true} className={classes.title} variant="h5" gutterBottom>
          {post.title}
        </Typography>
      </ButtonBase>
      <CardActions className={classes.cardActions}>
        <Button
          className={classes.like}
          size="small"
          disabled={!user?.result}
          color="primary"
          onClick={() => dispatch(likePost(post._id))}
        >
          <Likes />
        </Button>
        {(isAdmin()) && (<>

          <Button
            className={classes.delete}
            size="small"
            color="primary"
            onClick={() => dispatch(deletePost(post._id))}
          >
            <DeleteIcon fontSize="small" />
            Deletar
          </Button>
        </>
        )}
      </CardActions>
    </Paper>
  );
};

export default Post;
