import * as api from '../api'
import {triggerAlert} from './alert'

//Actions Creators
import {CHECK_ELIGIBILITY, FETCH_JOBTITLES, FETCH_CARBRANDS, FETCH_CARMODELS, FETCH_PROPOSALS, FETCH_PROPOSAL} from "../constants/actionTypes";

export const fetchJobTitles = () => async (dispatch) => {
    try{
        const {data} = await api.fetchJobTitles();
        dispatch({type: FETCH_JOBTITLES, payload: data});
    }catch (e) {
        console.log(e);
    }
}

export const fetchCarBrands = (year) => async (dispatch) => {
    try{
        const {data} = await api.fetchCarBrands(year);
        dispatch({type: FETCH_CARBRANDS, payload: data.map(brand=>brand.name)});
    }catch (e) {
        console.log(e);
    }
}

export const fetchCarModels = (brand, year) => async (dispatch) => {
    try{
        const {data} = await api.fetchCarModels(year, brand);
        dispatch({type: FETCH_CARMODELS, payload: data});
    }catch (e) {
        console.log(e);
    }
}

export const createProposal = (setSending, proposal, history, setErrors) => async (dispatch) => {
    try{
        const {data} = await api.createProposal(proposal);
        dispatch(triggerAlert("Proposta Criada com sucesso!", "success"));
        history.push('/proposal');
    }catch (e) {
        console.log(e.response.data);
        dispatch(triggerAlert("Ocorreu um erro na criação de sua proposta", 'error'));
        setErrors(`No Campo "${e.response.data.details[0].target}": O Erro: ${e.response.data.details[0].message}`);
        setSending(false);
    }
}

export const fetchProposals = () => async (dispatch) => {
    try{
    const {data} = await api.fetchProposals();
    dispatch({type: FETCH_PROPOSALS, payload: data});
    }catch (e) {
        console.log(e);
    }
}

export const fetchProposal = (id) => async (dispatch) => {
    try{
        const {data} = await api.fetchProposal(id);
        dispatch({type: FETCH_PROPOSAL, payload: data});
    }catch (e) {
        console.log(e);
    }
}

export const checkEligibility = (cpf) => async (dispatch) => {
    try{
    const {data} = await api.checkEligibility(cpf);
    dispatch({type: CHECK_ELIGIBILITY, payload: {error: false, data: data}});
    }catch (e) {
        dispatch({type: CHECK_ELIGIBILITY, payload: {error: true, data: null}});
        console.log(e);
    }
}


export const translateFields = (field) => {
    switch (field) {
      case "fullName":
        return "Nome completo";
      case "email":
        return "E-mail";
      case "birthDate":
        return "Data de nascimento";
      case "cellphone":
        return "Celular";
      case "cellphoneCode":
        return "Código do celular";
      case "monthlyIncome":
        return "Renda mensal";
      case "professionalStatus":
        return "Status profissional";
      case "jobTitle":
        return "Cargo";
      case "timeOfEmployment":
        return "Tempo de empresa";
      case "authorizationTerms":
        return "Termos de autorização";
      case "postalCode":
        return "CEP";
      case "cpf":
        return "CPF";
      case "optIns":
        return "Opt-ins";
      case "whatsApp":
        return "WhatsApp";
      case "currency":
        return "Moeda";
      case "amount":
        return "Valor";
      case "term":
        return "Parcela";
      case "debt":
        return "Dívida";
      case "address":
        return "Endereço";
      case "number":
        return "Número";
      case "complement":
        return "Complemento";
      case "neighborhood":
        return "Bairro";
      case "city":
        return "Cidade";
      case "state":
        return "Estado";
      case "country":
        return "País";
      case "postalCode":
        return "CEP";
      case "hasDeed":
        return "Possui título";
      case "realEstateType":
        return "Tipo de imóvel";
      case "productType":
        return "Tipo de produto";
      case "purpose":
        return "Finalidade";
      case "borrower":
        return "Titular";
      case "intendedCredit":
        return "Crédito pretendido";
      case "conditions":
        return "Condições";
      case "collateral":
        return "Colateral";
      default:
        return field;
    }
  }