import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import Legenda from "./Legenda/Legenda";
import Marketing from "../Marketing/Marketing";
import Carousel from "react-material-ui-carousel";
import Aviso from "./Aviso/Aviso";
import { useDispatch, useSelector } from "react-redux";
import { getAvisos, deleteAviso } from "../../actions/avisos";
import { isAdmin } from "../../actions/auth";
import legendas from "./legendas";
import Popup from "../Popup";
import PopupImg from "../../images/Popup2.png";
import { CLOSE_POPUP } from "../../constants/actionTypes";

const PopupHome = () => {
  return (
    <div width="450px" display="flex">
      <Typography variant="h6">
        {" "}
        Não sabe solicitar um material?{" "}
        <a href="https://youtu.be/Lqh86DX-E8k">Clique aqui</a> para aprender!{" "}
      </Typography>
      <img src={PopupImg} width="100%" />
    </div>
  );
};

const Home = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { avisos, isLoading, popup } = useSelector((state) => state.avisos);
  console.log(popup);
  const dispatch = useDispatch();
  var avisoAtual = 0;

  useEffect(() => {
    dispatch(getAvisos());
  }, [dispatch]);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const closePopup = () => {
    dispatch({ type: CLOSE_POPUP });
  };

  return (
    <Box style={{ position: "absolute", marginTop: "15px" }} left="0" right="0">
      <Popup
        startsOpen={popup}
        handleClose={closePopup}
        title="HomePopup"
        children={<PopupHome />}
      />
      <Grid style={{ height: "100%" }} container spacing={0}>
        <Grid
          className={classes.avisoImg}
          container
          direction="column"
          item
          xs={12}
          md={4}
          lg={4}
        >
          <Box>
            {isAdmin() && (
              <Button
                color="secondary"
                onClick={() => dispatch(deleteAviso(avisos[avisoAtual]._id))}
              >
                Deletar Aviso
              </Button>
            )}
            <Carousel onChange={(index) => (avisoAtual = index)}>
              {avisos?.map((item, i) => (
                <Aviso key={i} item={item} />
              ))}
            </Carousel>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box style={{ marginTop: "30px", width: "auto" }}>
                <Box
                  boxShadow="6"
                  justifyContent="center"
                  className={classes.avisos}
                >
                  <Box alignSelf="flex-end" justifyContent="center">
                    {legendas.length
                      ? legendas.map((legenda, i) => (
                          <Legenda
                            key={i}
                            link={legenda.link || ""}
                            message={legenda.message}
                            color={legenda.color}
                          />
                        ))
                      : null}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Marketing />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
