import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
    },
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
    },
    bodySection: {
        display: "grid",
        margin: "30px auto 0 auto",
        gap: "20px",
        gridTemplateColumns: "1fr 1fr",
    }
}));
