import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { flexbox } from "@material-ui/system";

import useStyles from "./styles";
import { createAviso } from "../../../../actions/avisos";

const Form = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("profile"));
  const history = useHistory();
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);

  const [postData, setPostData] = useState({
    title: "",
    selectedFile: [],
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (sending) alert("Por favor aguarde o envio do formulario")
      else {
        const data = new FormData();
        data.append("file", postData.selectedFile[0]);
        data.append("title", postData.title);
        dispatch(createAviso(data, history));
        setSending(true);
      }
    } catch (e) {
      console.log(e);
      alert(e.message);
      setSending(false);
    }
    // clear();
  };
  // if(!user?.result?.name){
  //   return (
  //     <Paper className={classes.paper} elevation={6}>
  //       <Typography variant="h6" align="center">
  //         Por favor, entre na sua conta para enviar Aviso.
  //       </Typography>
  //     </Paper>
  //   );
  // }

  const clear = () => {
    setPostData({
      title: '',
      selectedFile: []
    });
  };

  return (
    <Paper className={classes.paper}>
      <form
        autoComplete="off"
        noValidate
        className={`${classes.root} ${classes.form}`}
        lg={12}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Typography className={classes.fontFaceNP} variant="h6">
          Criando um Aviso
        </Typography>
        <TextField
          name="title"
          variant="outlined"
          label="Título"
          fullWidth
          value={postData.title}
          onChange={(e) => setPostData({ ...postData, title: e.target.value })}
        />

        <Box style={{ height: "42px", width: "100%", marginBottom: "10px" }} display="flex" justifyContent="center">
          <Button
            style={{ backgroundColor: "grey", color: "white" }}
            variant="contained"
            component="label"
          >
            Upload File
            <input
              type="file"
              hidden
              onChange={(e) =>
                setPostData({ ...postData, selectedFile: e.target.files })
              }
            />
          </Button>
        </Box>

        <Button
          style={!sending ? { width: 100, backgroundColor: "#00d2c6" } : { width: 100, backgroundColor: "grey" }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          fullWidth
        >
          Enviar
        </Button>
        <Button
          style={{
            width: 100,
            marginLeft: "4px",
            backgroundColor: "rgba(10, 150, 255, 0.7)",
          }}
          variant="contained"
          color="secondary"
          size="small"
          onClick={clear}
          fullWidth
        >
          Limpar
        </Button>

      </form>
    </Paper>
  );
};

export default Form;
