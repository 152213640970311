import React, { useEffect } from "react";
import {
  Paper,
  Typography,
  CircularProgress,
  Divider,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { ExpandMore, FileCopy } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import Comments from "./Comments";
import {
  properties,
  getLabelOfField,
  fields,
  translateType,
} from "../properties";

import { fetchLogo, isAdmin } from "../../../../actions/auth";
import {
  getRequest,
  deleteRequest,
  completeRequest,
  fetchRequestFile,
} from "../../../../actions/requests";
import useStyles from "./styles";

function AlertDialog(props) {
  const { onClose, open, onConfirm, title, text, confirmText, cancelText } =
    props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelText}</Button>
        <Button onClick={onConfirm} autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const fetchFiles = async (image) => {
  var blobs = [];
  for (const file of image) {
    await fetchRequestFile(file._id).then((response) => blobs.push(response));
  }
  return blobs;
};

export default function RequestDetails() {
  const { request, isLoading } = useSelector((state) => state.requests);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [post, setPost] = React.useState(null); // v1 exclusive
  const [files, setFiles] = React.useState([]);
  const [logo, setLogo] = React.useState(null);
  const [confirmation, setConfirmation] = React.useState(false);
  const [images, setImages] = React.useState({ foto: null, foto2: null }); // v2 exclusive
  const version = request?.version || 2;
  if (request?.version < 2) {
    // version 1 exclusive logic
    // create a shallow copy to mantain request state.
    var posts = request?.post.slice() || [];
    // remove the first element that will be sent to comments.
    posts.shift();
    // check if component is mounted
  } else if (request?.version >= 2) {
    // version 2 exclusive logic
  }
  const isRendered = React.useRef(false);
  useEffect(() => {
    isRendered.current = true;
    return () => {
      setPost(null);
      isRendered.current = false;
      setFiles([]);
      setImages({ foto: null, foto2: null });
    };
  }, []);
  useEffect(() => {
    dispatch(getRequest(id));
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (request?._id == id) {
      try {
        if (request.creator.logo)
          fetchLogo(request.creator._id, "user")
            .then(
              (response) =>
                isRendered.current &&
                setLogo(window.URL.createObjectURL(response))
            )
            .catch((error) => console.log(error));
        if (request?.version < 2) {
          if (request._id === id) setPost(request.post[0]);
          if (request.post[0]?.image)
            fetchFiles(request.post[0].image).then(
              (result) => isRendered.current && setFiles(result)
            );
        } else {
          if (request._id === id) {
            setFiles([]);
            setImages({ foto: null, foto2: null });
          }
          const { foto, foto2, refImages } = request.files;
          if (foto) {
            const fotos = [foto];
            if (foto2) fotos.push(foto2);
            fetchFiles(fotos).then((result) => {
              const images = { foto: null, foto2: null };
              result.forEach((blob, index) => {
                if (index === 0) images.foto = blob;
                else images.foto2 = blob;
              });
              isRendered.current && setImages(images);
            });
          }
          if (refImages) {
            fetchFiles(refImages).then(
              (result) => isRendered.current && setFiles(result)
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [request]);

  const ImageComponent = ({ blob }) => {
    //console.log(blob);
    const classes = useStyles();
    if (!blob) return <div></div>;
    const type = blob.type.slice(0, blob.type.indexOf("/"));
    const fileUrl = window.URL.createObjectURL(blob);
    var body = "";
    if (type === "image") {
      body = (
        <img
          onClick={() => download(type, fileUrl)}
          width="600"
          alt="imageX"
          src={fileUrl}
        />
      );
    } else if (type === "video") {
      body = (
        <video height="350" width="auto" controls>
          <source src={fileUrl} type={blob.type} />
        </video>
      );
    } else {
      body = (
        <FileCopy
          onClick={() => download(type, fileUrl)}
          style={{ fontSize: "8rem", color: "#00d2c6" }}
          className={classes.file}
        />
      );
    }

    return body;
  };

  const getComponentByField = (field, value, index) => {
    if (value === null || value === "") return null;
    const property = properties.find(
      (property) => property.key === request.type
    );

    let label =
      (property.labels && property?.labels[field]) || getLabelOfField(field);
    if (value === null) return null;
    switch (field) {
      case "foto":
      case "foto2":
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px solid #00d2c6",
              borderRadius: "10px",
              padding: "5px",
              margin: "10px",
            }}
          >
            <Typography variant="subtitle1">{label}</Typography>
            <ImageComponent blob={value} />
          </div>
        );
      default:
        return (
          <Typography align="center" variant="h6" key={index}>
            {label}: {value}
          </Typography>
        );
    }
  };

  if (!request) return null;

  if (isLoading) {
    return (
      <Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size="7em" />
      </Paper>
    );
  }

  const handleComplete = (comment, clear) => {
    try {
      if (comment.files.length === 0 && comment.text === "")
        throw "Please enter a comment";
      const data = new FormData();
      data.set("text", comment.text);
      const files = comment.files;
      for (var i = 0; i < files?.length; i++) {
        var file = files[i];
        data.append("files", file);
      }
      dispatch(completeRequest(id, data, history));
      clear();
    } catch (e) {
      alert(e);
    }
  };

  const handleDeleteRequest = () => {
    setFiles([]);
    dispatch(deleteRequest(request._id));
    history.push("/requests");
  };

  const handleCompleteRequest = () => {
    dispatch(completeRequest(request._id, null, history));
  };

  const download = (type, url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", type); //or any other extension
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      link.parentNode.removeChild(link);
    }, 10);
  };

  return version < 2 ? (
    // Version 1
    <Paper
      style={{ margin: "20px", padding: "15px", borderRadius: "15px" }}
      elevation={6}
    >
      <div className={classes.card}>
        <div className={classes.section}>
          {request.complete ? (
            <Typography
              style={{ marginBottom: "6px" }}
              variant="h4"
              color="primary"
              component="h2"
              p={6}
            >
              Completo em:{" "}
              {new Date(Date.parse(request.completeAt)).toLocaleDateString()}
            </Typography>
          ) : (
            <Typography
              style={{ marginBottom: "6px" }}
              variant="h5"
              align="center"
              component="h4"
              p={6}
            >
              {request.type}
            </Typography>
          )}

          <Typography
            style={{ marginBottom: "12px" }}
            variant="h3"
            align="center"
            component="h3"
            p={6}
          >
            {request.title}
          </Typography>

          <Divider />

          <Typography
            style={{ marginBottom: "6px", width: "70%", margin: "auto" }}
            gutterBottom
            variant="h6"
            align="center"
            component="p"
          >
            {post?.text}
          </Typography>

          <Divider style={{ marginBottom: "12px" }} />

          <Typography
            style={{ marginBottom: "6px" }}
            variant="body1"
            align="center"
            component="p"
          >
            WhatsApp: {request.telefone}
          </Typography>
          <Typography
            style={{ marginBottom: "12px" }}
            variant="body1"
            align="center"
            component="p"
          >
            E-mail: {request.creator.email}
          </Typography>
          <Typography style={{ marginBottom: "12px" }} align="center">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setConfirmation(true)}
            >
              Apagar Request
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleCompleteRequest(request._id)}
            >
              Completar
            </Button>
            <AlertDialog
              open={confirmation}
              onClose={() => setConfirmation(false)}
              onConfirm={handleDeleteRequest}
              title={`Tem certeza que deseja apagar a request ${request.title}?`}
              text={`Esta ação não pode ser desfeita.`}
              confirmText="Apagar"
              cancelText="Cancelar"
            />
          </Typography>

          {request.post[0].image.length > 0 && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Visualize as imagens aqui:
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.imageSection}>
                  {files.length > 0 ? (
                    files.map((blob, i) => {
                      const type = blob.type.slice(0, blob.type.indexOf("/"));
                      const fileUrl = window.URL.createObjectURL(blob);
                      var body = "";
                      if (type === "image") {
                        body = (
                          <img
                            onClick={() => download(type, fileUrl)}
                            width="300"
                            alt="imageX"
                            src={fileUrl}
                          />
                        );
                      } else if (type === "video") {
                        body = (
                          <video height="350" width="auto" controls>
                            <source src={fileUrl} type={blob.type} />
                          </video>
                        );
                      } else {
                        body = (
                          <FileCopy
                            onClick={() => download(type, fileUrl)}
                            style={{ fontSize: "8rem", color: "#00d2c6" }}
                            className={classes.file}
                          />
                        );
                      }

                      return (
                        <Grid key={i} item xs={4}>
                          {body}
                        </Grid>
                      );
                    })
                  ) : (
                    <CircularProgress size="7em" />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
          <Typography style={{ paddingTop: 20 }} variant="h6">
            Criado por: {request.creator.name}, Código: {request.creator.codigo}
          </Typography>
          <Typography variant="body1">
            {moment(request.createAt).fromNow()}
          </Typography>
          {logo && (
            <img
              src={logo}
              alt="logo"
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
            />
          )}
          <Divider style={{ margin: "20px 0" }} />
          {/* <Comments posts={posts} id={id} handleComplete={handleComplete} /> */}
        </div>
      </div>
    </Paper>
  ) : (
    // Version 2
    <Paper
      style={{ margin: "20px", padding: "15px", borderRadius: "15px" }}
      elevation={6}
    >
      <div className={classes.card}>
        <div className={classes.section}>
          {request.complete && (
            <Typography
              style={{ marginBottom: "6px" }}
              variant="h4"
              color="primary"
              component="h2"
              p={6}
            >
              Completo em:{" "}
              {new Date(Date.parse(request.completeAt)).toLocaleDateString()}
            </Typography>
          )}
          <Typography
            style={{ marginBottom: "6px" }}
            variant="h5"
            align="center"
            component="h4"
            p={6}
          >
            {translateType(request.type)}
          </Typography>
          <Divider />
          <div className={classes.fieldsContainer}>
            {Object.entries(images).map(([key, value], index) => {
              return getComponentByField(key, value, index);
            })}
            <div className={classes.bodySection}>
              {" "}
              {/* className={index === 0 ? classes.imageSection : classes.restSection} */}
              {Object.entries(request.body).map(([key, value], index) =>
                getComponentByField(key, value, index)
              )}
            </div>
          </div>
          <Divider style={{ marginBottom: "12px" }} />

          <Typography
            style={{ marginBottom: "6px" }}
            variant="body1"
            align="center"
            component="p"
          >
            WhatsApp: {request.telefone_entrega}
          </Typography>
          <Typography
            style={{ marginBottom: "12px" }}
            variant="body1"
            align="center"
            component="p"
          >
            E-mail do Franqueado: {request.creator.email}
          </Typography>
          {isAdmin() && (
            <Typography style={{ marginBottom: "12px" }} align="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setConfirmation(true)}
              >
                Apagar Request
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleCompleteRequest(request._id)}
              >
                Completar
              </Button>
              <AlertDialog
                open={confirmation}
                onClose={() => setConfirmation(false)}
                onConfirm={handleDeleteRequest}
                title={`Tem certeza que deseja apagar este request?`}
                text={`Esta ação não pode ser desfeita.`}
                confirmText="Apagar"
                cancelText="Cancelar"
              />
            </Typography>
          )}

          {files.length > 0 && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Visualize as imagens de referência aqui:
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.imageSection}>
                  {files.map((blob, i) => {
                    const type = blob.type.slice(0, blob.type.indexOf("/"));
                    const fileUrl = window.URL.createObjectURL(blob);
                    var body = "";
                    if (type === "image") {
                      body = (
                        <img
                          onClick={() => download(type, fileUrl)}
                          width="300"
                          alt="imageX"
                          src={fileUrl}
                        />
                      );
                    } else if (type === "video") {
                      body = (
                        <video height="350" width="auto" controls>
                          <source src={fileUrl} type={blob.type} />
                        </video>
                      );
                    } else {
                      body = (
                        <FileCopy
                          onClick={() => download(type, fileUrl)}
                          style={{ fontSize: "8rem", color: "#00d2c6" }}
                          className={classes.file}
                        />
                      );
                    }

                    return (
                      <Grid key={i} item xs={4}>
                        {body}
                      </Grid>
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
          <Typography style={{ paddingTop: 20 }} variant="h6">
            Criado por: {request.creator.name}, Código: {request.creator.codigo}
          </Typography>
          {logo && (
            <img
              src={logo}
              alt="logo"
              style={{ width: "100px", height: "100px", borderRadius: "50%" }}
            />
          )}
          <Typography variant="body1">
            {moment(request.createAt).fromNow()}
          </Typography>
          <Divider style={{ margin: "20px 0" }} />
        </div>
      </div>
    </Paper>
  );
}
