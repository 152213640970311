import { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core';
import { isAdmin } from '../../../../actions/auth'
import { fetchRequestFile } from "../../../../actions/requests";
import FileCopyIcon from '@material-ui/icons/FileCopy'

const classes = makeStyles((theme) => ({
    commentsOuterContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    commentsInnerContainer: {
        height: '200px',
        overflowY: 'auto',
        marginRight: '30px',
    },
}));


const fetchFiles = async (images) => {
    var blobs = []
    for (const file of images) {
        await fetchRequestFile(file._id).then((response) => blobs.push(response))
    } return blobs;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ posts, id, handleComplete }) => {
    // const [comments, setComments] = useState(posts);
    const [comment, setComment] = useState({
        text: "",
        files: [],
    });
    var render = true;
    const [files, setFiles] = useState([]);
    useEffect(() => {
        return () => {
            render = false;
        }
    }, []);

    useEffect(() => {
        if (posts[0]?.image) fetchFiles(posts[0].image).then(result => render && setFiles(result))
    }, [posts]);

    const clear = () => {
        setComment({
            text: "",
            files: [],
        });
    }

    const download = (type, url) => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", type); //or any other extension
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            link.parentNode.removeChild(link);
        }, 10);
    };
    return (<Box className={classes.commentsOuterContainer}>
        {posts && <Box className={classes.commentsInnerContainer}>
            {posts.map((comment, index) => {
                return (
                    <Box style={{ marginLeft: "2%", marginRight: "2%" }} key={index}>
                        <Typography gutterBottom variant="h5">{comment.author.name}:</Typography>
                        <Typography style={{ borderBottom: "solid 1px" }} gutterBottom variant="subtitle1" >{comment.text}</Typography>

                        <Grid container direction="row">

                            {files.map((blob, i) => {
                                const type = blob.type.slice(0, blob.type.indexOf("/"));
                                const fileUrl = window.URL.createObjectURL(blob);
                                var body = "";
                                if (type === "image") {
                                    body = (
                                        <img onClick={() => download(type, fileUrl)} width="300" alt="imageX" src={fileUrl} />
                                    );
                                } else if (type === "video") {
                                    body = (
                                        <Box style={{ height: "400", width: "100%" }}>
                                            <video height="350" width="auto" controls>
                                                <source src={fileUrl} type={blob.type} />
                                            </video>
                                        </Box>
                                    );
                                } else {
                                    body = (
                                        <FileCopyIcon onClick={() => download(type, fileUrl)}
                                            style={{ fontSize: "8rem", color: "#00d2c6" }}
                                            className={classes.file}
                                        />
                                    );
                                }

                                return (
                                    <Grid key={i} item xs={4}>
                                        {body}
                                    </Grid>
                                );


                            })}
                            {/* {image.length !== post.marketingFile.length && (<CircularProgress/>)} */}
                        </Grid>
                    </Box>)
            })}
        </Box>}
        {isAdmin() && <Box>

            <Box style={{ marginBottom: "10px", marginTop: "30px", }} display="flex" justifyContent="center">

                <Typography align="center" variant="h5" gutterBottom >Adicionar um Comentário:</Typography>

            </Box>
            <Box style={{ marginBottom: "10px" }} display="flex" justifyContent="center">
                <TextField
                    style={{ width: "60%" }}
                    rows={6} variant="outlined" label="Coloque seu comentário..." multiline value={comment.text}
                    onChange={(event) => setComment({ ...comment, 'text': event.target.value })} />
            </Box>
            <Box display="flex" justifyContent="center">
                <Button
                    style={{ marginRight: "10px", backgroundColor: "#00d2c6", color: "white" }}
                    variant="contained"
                    component="label"
                >
                    Adicionar Arquivo
                    <input
                        multiple={true}
                        type="file"
                        hidden
                        onChange={(e) =>
                            setComment({ ...comment, files: e.target.files })
                        }
                    />

                </Button>

                <Button style={{ backgroundColor: "#00d2c6", }} disabled={!comment} color="primary" variant="contained" onClick={() => handleComplete(comment, clear)}>Entregar</Button>
            </Box>
        </Box>}
    </Box>)
}
