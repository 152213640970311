import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import BrushIcon from "@material-ui/icons/Brush";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useStyles, useToolbarStyles } from "./styles";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import { useHistory } from "react-router-dom";


const keys = [
  { key: "type", name: "Tipo" },
  { key: "telefone_entrega", name: "Telefone" },
  { key: "createAt", name: "Data" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: keys[0].key,
    numeric: false,
    disablePadding: true,
    label: keys[0].name,
  },
  {
    id: keys[1].key,
    numeric: true,
    disablePadding: false,
    label: keys[1].name,
  },
  {
    id: keys[2].key,
    numeric: true,
    disablePadding: false,
    label: keys[2].name,
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <BrushIcon />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Solicitações
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// eslint-disable-next-line import/no-anonymous-default-export
const UserTable = ({ requests }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createAt");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showComplete, setShowComplete] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const history = useHistory();

  const isInTitleOrName = (request, searchTerm) => {
    return (
      request.type?.toLowerCase().includes(searchTerm)
    );
  };

  const handleSearch = (search) => {
    //get requests that contain search in their name
    const searchTerm = search.toLowerCase();
    setRows(requests.filter((request) => isInTitleOrName(request, searchTerm)));
  };

  useEffect(() => {
    setRows(requests);
  }, [requests]);

  useEffect(() => {

    if (showComplete) {
      const novosRequests = [];
      for (const request of requests) {
        if (request.complete === true) novosRequests.push(request);
      }
      setRows(novosRequests);
    } 
    else setRows(requests);

    // showComplete ? setRows(requests.filter((request)=>request.complete)) : setRows(requests);

  }, [showComplete]);

  const handleClick = (id) => history.push(`/request/${id}`);

  const handleShowComplete = (e) => setShowComplete(!showComplete);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  /* 
  request = {
    title: ""
    telefone: ""
    creatAt: ""
    complete: true || false
  }
*/

  const rowColor = (complete, createAt) => {
    if (complete) return "#76ff03";
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TextField
          style={{ padding: "10px" }}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {/* <FormControlLabel
          style={{ padding: "8px" }}
          control={
            <Switch checked={showComplete} onClick={handleShowComplete} />
          }
          label="Mostrar Completos"
        /> */}
        {/* <EnhancedTableToolbar /> */}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={true ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-${index}`;

                  return (
                    <TableRow
                      hover={!row.complete}
                      style={{
                        backgroundColor: rowColor(
                          row.complete,
                          new Date(row.createAt)
                        ),
                      }}
                      onClick={(event) => handleClick(row._id)}
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell>
                        <BrushIcon />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row[keys[0].key]}
                      </TableCell>
                      <TableCell align="right">{row[keys[1].key]}</TableCell>
                      <TableCell align="right">
                        {new Date(row[keys[2].key]).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (true ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default UserTable;