import React, { useState, useRef } from "react";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  setRef,
  Input,
  InputAdornment,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";

import Joi from "joi";
import useStyles from "./styles";
import { createRequest } from "../../../../actions/requests";
import {
  properties,
  getLabelOfField,
  getDefaultOfType,
  getDefaultValidation,
} from "../properties";
import { triggerAlert } from "../../../../actions/alert";
import Popup from "../../../../components/Popup";
import PopupImg from "../../../../images/Popup1.png";

const PopupRequest = () => {
  return (
    <div width="450px" display="flex">
      <img src={PopupImg} alt="" width="100%" />
    </div>
  );
};

const OptionalComponent = ({
  text,
  label,
  fieldName,
  postData,
  placeholder,
  setPostData,
}) => {
  const [visibility, setVisibility] = useState(false);

  const changeVisibility = (newVisibility) => {
    setPostData({ ...postData, [fieldName]: "" }); // TODO: works only if all optional field default is "".
    setVisibility(newVisibility);
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        checked={visibility}
        onChange={(e) => changeVisibility(e.target.checked)}
      />
      {visibility ? (
        <TextField
          label={label}
          value={postData[fieldName]}
          style={{ width: "100%" }}
          onChange={(e) =>
            setPostData({ ...postData, [fieldName]: e.target.value })
          }
          margin="normal"
          variant="outlined"
          placeholder={placeholder}
        />
      ) : (
        <Typography
          style={{ padding: "25px 0px", marginLeft: "5px" }}
          variant="subtitle1"
        >
          {text}
        </Typography>
      )}
    </div>
  );
};

const findFieldsByType = (type) =>
  Object.entries(properties.find((property) => property.key === type).fields);

const getComponentOfField = (
  key,
  type,
  postData,
  setPostData,
  classes,
  index,
) => {
  const property = properties.find((property) => property.key === type);

  let componentType =
    (property.type && property.type[key]) || getDefaultOfType(key);
  let label =
    (property.labels && property?.labels[key]) || getLabelOfField(key);
  let placeholder =
    (property.defaultValues && property?.defaultValues[key]) || "";
  const examples = (property.examples && property?.examples[key]) || null;

  let field;
  switch (componentType) {
    case "text":
      field = (
        <TextField
          label={label}
          value={postData[key]}
          onChange={(e) => setPostData({ ...postData, [key]: e.target.value })}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          placeholder={placeholder}
          {...(key === "observacao" && { multiline: true, rows: "4" })}
        />
      );
      break;
    case "select":
      field = (
        <FormControl>
          <InputLabel>{label}</InputLabel>
          <NativeSelect
            className={classes.selectWrapper}
            value={postData[key]}
            onChange={(e) =>
              setPostData({ ...postData, [key]: e.target.value })
            }
          >
            {property.defaultValues[key].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      );
      break;
    case "custom-text":
      field = (
        <TextField
          label={label}
          value={postData[key]}
          onChange={(e) => setPostData({ ...postData, [key]: e.target.value })}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+Ágil</InputAdornment>
            ),
          }}
        />
      );
      break;
    case "image":
      field = (
        <div className={classes.imagePicker}>
          <input
            accept="image/*"
            className={classes.input}
            id={`contained-button-file-${key}`}
            type="file"
            hidden
            onChange={(e) =>
              setPostData({ ...postData, [key]: e.target.files[0] })
            }
          />
          <Typography variant="h6">{label}</Typography>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h6" className={classes.outlined}>
              {postData[key] ? postData[key].name : "Selecione uma imagem..."}
            </Typography>
            <label htmlFor={`contained-button-file-${key}`}>
              <Button
                variant="contained"
                className={classes.button}
                color="primary"
                component="span"
              >
                Upload
              </Button>
            </label>
          </div>
        </div>
      );
      break;
    case "optional":
      field = (
        <OptionalComponent
          fieldName={key}
          text={`Possui ${label}?`}
          label={label}
          postData={postData}
          setPostData={setPostData}
          placeholder={placeholder}
        />
      );
      break;
    default:
      return null;
  }
  if (examples) {
    field = (
      <div style={{ display: "flex", flexDirection: "column" }} key={index}>
        {field}
        <Typography style={{ margin: "0px 0px 8px 5px" }} variant="caption">
          {examples}
        </Typography>
      </div>
    );
  } else {
    <div key={index}>{field}</div>;
  }
  return field;
};

const Request = () => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("profile"));
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [postData, setPostData] = useState(
    properties.filter((property) => !property.inactive)[0].fields,
  );
  const [type, setType] = useState(
    properties.filter((property) => !property.inactive)[0].key,
  );
  const [refImages, setRefImages] = useState({ ref: [] });
  const dispatch = useDispatch();
  const fields = findFieldsByType(type);
  const [telefone, setTelefone] = useState(
    localStorage.getItem("telefone")?.replace(/"/g, ""),
  );

  const handleValidation = () => {
    const fields = findFieldsByType(type);
    const validationObject = Object.fromEntries(
      fields.map((field) => [field[0], getDefaultValidation(field[0])]),
    ); //[key, value]
    const objectSchema = Joi.object(validationObject);
    const { error } = objectSchema.validate(postData);
    if (error) {
      console.log(error);
      dispatch(triggerAlert(error.details[0].message, "error"));
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (sending) alert("Aguarde, estamos enviando o pedido.");
      else {
        if (!handleValidation()) return;
        if (telefone)
          localStorage.setItem("telefone", JSON.stringify(telefone));
        handleValidation();
        const data = new FormData();
        Object.entries(postData).forEach(([key, value]) => {
          if (value === null || value === "") return;
          data.set(key, value);
        });
        data.set("telefone_entrega", telefone);
        data.set("version", 2.2);
        data.set("type", type);
        for (var i = 0; i < refImages?.length; i++) {
          var file = refImages[i];
          data.append("refImages", file);
        }
        console.log("Form sended");
        dispatch(createRequest(data, history, setSending));
        setSending(true);
      }
    } catch (error) {
      alert(error);
      setSending(false);
    }
    // clear();
  };

  if (!user?.result?.name) {
    return (
      <Paper className={classes.paper} elevation={6}>
        <Typography variant="h6" align="center">
          Por favor entre na sua conta para fazer solicitações.
        </Typography>
      </Paper>
    );
  }
  const clear = async () => {
    setPostData(properties[0].fields);
    setRefImages({ ref: [] });
  };

  const showFileNames = (files) => {
    let text = "";
    for (const file of files) {
      text += file.name + " ";
    }
    return (
      <Typography align="justify" variant="subtitle2">
        {text}
      </Typography>
    );
  };

  const handleTypeChange = (value) => {
    setPostData(properties.find((property) => property.key === value).fields);
    setType(value);
    setRefImages([]);
  };

  return (
    <Paper className={classes.paper}>
      <Popup startsOpen={true} children={PopupRequest()} />
      <Alert severity="warning">
        A Solicitação tem um prazo de 240 horas úteis
      </Alert>
      <Alert severity="info">Limite de 5 solicitações por dia</Alert>

      <form
        autoComplete="off"
        noValidate
        className={`${classes.root} ${classes.form}`}
        lg={12}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Box display="inline" width="90%">
          <Typography
            className={classes.fontFaceNP}
            style={{ textAlign: "center" }}
            variant="h5"
          >
            Solicite um Material
          </Typography>
          <Box className={classes.selectWrapper}>
            <Typography variant="h6" style={{ marginRight: "10px" }}>
              Tipo:{" "}
            </Typography>
            <NativeSelect
              value={type}
              onChange={(e) => handleTypeChange(e.target.value)}
              inputProps={{
                name: "type",
                id: "type-native-helper",
              }}
            >
              {properties.map(
                (property) =>
                  !property.inactive && (
                    <option key={property.key} value={property.key}>
                      {property.label}
                    </option>
                  ),
              )}
            </NativeSelect>
          </Box>
          {type && (
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginBottom: "10px" }}
              justifyContent="center"
            >
              <TextField
                label="Whatsapp (para contato em caso de dúvidas)"
                name="telefone_entrega"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
              <div style={{ border: "3px solid #00d2c6", margin: "10px" }} />
              {fields.map(([key], index) =>
                getComponentOfField(
                  key,
                  type,
                  postData,
                  setPostData,
                  classes,
                  index,
                ),
              )}
            </Box>
          )}
        </Box>
        <Grid direction="row" container spacing={6} justify="center">
          <Grid item>
            <Button
              style={{ backgroundColor: "grey", color: "white" }}
              variant="contained"
              component="label"
            >
              Imagens de Referencia
              <input
                multiple={true}
                type="file"
                hidden
                onChange={(e) => setRefImages(e.target.files)}
              />
            </Button>
            <div>{refImages.length > 0 && showFileNames(refImages)}</div>
          </Grid>
        </Grid>
        <Button
          className={classes.buttonSubmit}
          style={
            !sending
              ? { width: 100, backgroundColor: "#00d2c6" }
              : { width: 100, backgroundColor: "grey" }
          }
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          fullWidth
        >
          Enviar
        </Button>
        <Button
          className={classes.buttonSubmit}
          style={{ width: 100, backgroundColor: "rgba(10, 150, 255, 0.7)" }}
          variant="contained"
          color="secondary"
          size="small"
          onClick={clear}
          fullWidth
        >
          Limpar
        </Button>
      </form>
    </Paper>
  );
};

export default Request;
