import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
Geral: {
   width: "60%",
   height: "80%",
   margin: "auto",
   [theme.breakpoints.down('md')]: {
    width: "80%"
   },
   [theme.breakpoints.down('sm')]: {
    width: "98%"
   },
  },

card: {
  width: "50%",
  margin: "auto",
  marginBottom: "20px",
  backgroundColor: "white",
  borderRadius: "5px",
  [theme.breakpoints.down('md')]: {
   width: "70%"
  },
  [theme.breakpoints.down('sm')]: {
   width: "80%"
  },
},

buttonsDiv: {
   paddingTop: "40px",
   paddingBottom: "30px",
   padding: "20px",
   [theme.breakpoints.down('sm')]: {
    paddingTop: "20px",
   paddingBottom: "10px",
   padding: "10px",
  },
  display: "flex",
},

buttons: {
  height: "50px", 
  color: "white", 
  backgroundColor: "#00d2c6",
  [theme.breakpoints.down('sm')]: {
    width: "auto"
  },
  "&:hover": {
    backgroundColor: "#2eb0a9"
  },
},

gridStyle: {
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}

}));