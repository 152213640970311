import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    changePass: {
        backgroundColor: "white",
        boxShadow: "",        
        width: "400px", 
        height: "360px", 
        margin: "auto",
        marginTop: "80px",
    },
    fontFace: {
        fontFamily: "NoirPro",
        color: "#00d2c6",
        marginTop: "7px",
    },

}));

