import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  box: {
    margin: "auto",
    width: "auto",
    height: "80%",
    borderRadius: "5px"
  },
  icon: {
    marginLeft: "8px",
    margin: "5px",
    color: 'red'
  }
}));
