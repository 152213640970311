import { SET_ALERT, CLOSE_ALERT } from "../constants/actionTypes";
export const alert = (state = {message: "", severity: "success", "open": false }, action) => {
  switch (action.type) {
    case SET_ALERT:
      return { ...state, message: action.payload.message, severity: action.payload.severity, open: true };
    case CLOSE_ALERT:
      return { ...state, open: false };
    default:
      return state;
  }
};
