import { useState, useEffect } from 'react';
import { Paper, Tabs, Tab, Box, Typography } from '@material-ui/core';

import tableHeader1 from './images/1.jpeg';
import tableHeader2 from './images/2.jpeg';
import tableHeader3 from './images/3.jpeg';
import tableHeader4 from './images/4.jpeg';
import tableHeader5 from './images/5.jpeg';
import table1 from './images/1t.png';
import table2 from './images/2t.png';
import table3 from './images/3t.png';
import table4 from './images/4t.png';
import table5 from './images/5t.png';

function TabPanel(props) {
    const { children, value, index, header, table, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {header && <Box display="flex" style={{ paddingBottom: "20px" }} justifyContent="center" alignItems="center">
                        <img src={header} style={{ maxWidth: "95%" }} alt="tableHeader1" />
                    </Box>}

                    {table && <Box display="flex" style={{ overflowX: "scroll", whiteSpace: "nowrap" }} justifyContent="center" alignItems="center">
                        <img src={table} alt="table1" syle={{}} />
                    </Box>}
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function Tabelas(props) {
    const [value, setValue] = useState(0);
    return (
        <Paper square>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(e, v) => setValue(v)}
                    aria-label="disabled tabs example"
                >
                    <Tab label="Eletrônicos" />
                    <Tab label="Geral" />
                    <Tab label="Eventos e Bares" />
                    <Tab label="Restaurantes" />
                    <Tab label="Supermercados" />
                </Tabs>
            </Box>
            <TabPanel value={value} header={null} table={null} index={0}>
            <Box display="flex" style={{ paddingBottom: "20px" }} justifyContent="center" alignItems="center">
                    <img src={tableHeader1} style={{ maxWidth: "95%" }} alt="tableHeader1" />
                </Box>
                <Box style={{ overflowX: "scroll"}}>
                    <img src={table1} alt="table1" syle={{}} />
                </Box>
            </TabPanel>
            <TabPanel value={value} header={null} table={null} index={1}>
            <Box display="flex" style={{ paddingBottom: "20px" }} justifyContent="center" alignItems="center">
                    <img src={tableHeader2} style={{ maxWidth: "95%" }} alt="tableHeader1" />
                </Box>
                <Box style={{ overflowX: "scroll"}}>
                    <img src={table2} alt="table1" syle={{}} />
                </Box>
            </TabPanel>
            <TabPanel value={value} header={tableHeader3} table={table3} index={2}>
                
            </TabPanel>
            <TabPanel value={value} header={tableHeader4} table={table4} index={3}>
            </TabPanel>
            <TabPanel value={value} header={tableHeader5} table={table5} index={4}>
            </TabPanel>
        </Paper>
    )
}
