import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";
import { getUserRequests } from "../../../../actions/auth";
import moment from "moment";
import UserTable from "./UserTable/UserTable";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch();
  // const user = JSON.parse(localStorage.getItem('profile')).result;
  const classes = useStyles();
  const { requests } = useSelector((state) => state.auth);
  // const history = useHistory()

  useEffect(() => {
    dispatch(getUserRequests());
  }, [dispatch]);


  return (

<UserTable requests={requests}/>
  
  );
};
