/*
  property = {
    key: id
    label: label
    fields: default value state for fields
    defaultValues: value used for placeholder and default value if not filled
    examples: examples that will be shown bellow the field
    type: type of each field
    labels: labels of each field
    inactive: if new requests could be created with this property
  }
*/
import Joi from "joi";

export const properties = [
  {
    key: "apresentacao",
    label: "Apresentação",
    fields: { tema: "", telefone: "", endereco: "", observacao: "" },
    examples: {
      tema: "guia rápido, fgts",
    },
    inactive: true,
  },
  {
    key: "ass_email",
    label: "Ass de e-mail",
    fields: {
      nome: "",
      cargo: "",
      telefone: "",
      email: "",
      site: "",
      observacao: "",
    },
  },
  {
    key: "banner",
    label: "Banner",
    fields: {
      tamanho: "",
      telefone: "",
      endereco: "",
      tema: "",
      observacao: "",
    },
    defaultValues: {
      tamanho: "Padrão 120x70",
    },
    examples: {
      tema: "inss",
    },
  },
  {
    key: "brochura",
    label: "Brochura",
    fields: {
      tamanho: "",
      telefone: "",
      endereco: "",
      instagram: "",
      facebook: "",
      tema: "",
      observacao: "",
    },
    defaultValues: {
      tamanho: "Padrão A4",
    },
  },
  {
    key: "capa_facebook",
    label: "Capa para facebook",
    fields: { observacao: "" },
  },
  {
    key: "cartaz",
    label: "Cartaz",
    fields: {
      tamanho: "",
      telefone: "",
      endereco: "",
      instagram: "",
      facebook: "",
      observacao: "",
    },
  },
  {
    key: "cartao_visitas",
    label: "Cartão de visitas",
    fields: {
      modelo: "Padrão",
      nome: "",
      cargo: "",
      telefone: "",
      email: "",
      instagram: "",
      facebook: "",
      endereco: "",
      site: "",
      observacao: "",
    },
    type: {
      modelo: "select",
    },
    defaultValues: {
      modelo: ["Padrão", "+Ágilpay"],
    },
  },
  {
    key: "comunicados",
    label: "Comunicados",
    fields: { observacao: "" },
  },
  {
    key: "convites",
    label: "Convites",
    fields: { observacao: "" },
  },
  {
    key: "cracha",
    label: "Crachá",
    fields: {
      modelo: "Padrão",
      foto: null,
      nome: "",
      cargo: "",
      unidade: "",
      cnpj: "",
      cpf: "",
      certificado: "Febraban",
      observacao: "",
    },
    type: {
      modelo: "select",
      certificado: "select",
    },
    defaultValues: {
      modelo: ["Padrão", "+Ágilpay"],
      certificado: ["Febraban", "ANEPS"],
    },
    labels: {
      foto: "Foto",
    },
  },
  {
    key: "panfleto",
    label: "Panfleto",
    fields: {
      tamanho: "",
      modelo: "Frente e Verso",
      tema: "",
      telefone: "",
      endereco: "",
      instagram: "",
      facebook: "",
      observacao: "",
    },
    type: {
      modelo: "select",
    },
    defaultValues: {
      tamanho: "Padrão 105x148mm",
      modelo: ["Frente e Verso", "Frente"],
    },
    examples: {
      tema: "primeira pag / segunda pág",
    },
  },
  {
    key: "alteracao_logotipo",
    label: "Alteração de Logotipo",
    fields: { observacao: "" },
    type: {
      observacao: "custom-text",
    },
    examples: {
      observacao:
        "Será permitido apenas o seu nome ou da cidade em que você trabalha.",
    },
  },
  {
    key: "outdoor",
    label: "Outdoor",
    fields: {
      tamanho: "",
      tema: "",
      telefone: "",
      endereco: "",
      instagram: "",
      facebook: "",
      observacao: "",
    },
  },
  {
    key: "brindes",
    label: "Brindes",
    fields: { observacao: "" },
    examples: {
      observacao:
        "descrever o material escolhido, exemplo: mochila, tamanho da estampa 8x8",
    },
  },
  {
    key: "uniforme",
    label: "Uniforme",
    fields: { observacao: "" },
    examples: {
      observacao: (
        <a href="https://drive.google.com/drive/folders/14CSVAc9GmLYVjnb3HsipmLipevjuJQWQ?usp=sharing">
          Clique Aqui para ver o portfólio disponivel
        </a>
      ),
    },
  },
  {
    key: "postagem_redes",
    label: "Postagem para redes sociais",
    fields: { tema: "", telefone: "", observacao: "" },
  },
  {
    key: "inserir_numero",
    label: "Inserir número",
    fields: { telefone: "", observacao: "" },
  },
  {
    key: "projeto_fachada",
    label: "Projeto de fachada",
    fields: {
      foto: null,
      foto2: null,
      tamanho: "",
      tamanho2: "",
      telefone: "",
      instagram: "",
      facebook: "",
      observacao: "",
    },
    labels: {
      foto: "Foto de frente da loja",
      foto2: "Foto da parte de dentro da loja",
      tamanho: "Tamanho da placa",
      tamanho2: "Tamanho da porta",
    },
  },
  {
    key: "outros",
    label: "Outros",
    fields: { observacao: "" },
  },
  {
    key: "ebook",
    label: "E-Book",
    fields: { tema: "", telefone: "", endereco: "", observacao: "" },
    examples: {
      tema: "guia rápido, fgts",
    },
  },
];

export const getLabelOfField = (field) => {
  switch (field) {
    case "nome":
      return "Nome";
    case "cargo":
      return "Cargo";
    case "telefone":
      return "Telefone";
    case "email":
      return "E-mail";
    case "endereco":
      return "Endereço";
    case "instagram":
      return "Instagram";
    case "facebook":
      return "Facebook";
    case "site":
      return "Site";
    case "tamanho":
      return "Tamanho 1";
    case "tamanho2":
      return "Tamanho 2";
    case "foto":
      return "Foto 1";
    case "foto2":
      return "Foto 2";
    case "modelo":
      return "Modelo";
    case "tema":
      return "Tema";
    case "observacao":
      return "Observação";
    case "certificado":
      return "Certificado";
    case "cnpj":
      return "CNPJ";
    case "cpf":
      return "CPF";
    case "unidade":
      return "Unidade";
    default:
      return "ERRO!!!";
  }
};

export const fields = [
  "nome",
  "cargo",
  "telefone",
  "email",
  "endereco",
  "instagram",
  "facebook",
  "site",
  "tamanho",
  "foto",
  "foto2",
  "modelo",
  "tema",
  "observacao",
  "certificado",
  "cnpj",
  "cpf",
  "unidade",
];

export const getDefaultValidation = (field) => {
  switch (field) {
    case "endereco":
    case "site":
    case "telefone":
      return Joi.string().allow(null, "");
    case "email":
    case "nome":
    case "cargo":
    case "instagram":
    case "facebook":
    case "tamanho":
    case "tamanho2":
    case "modelo":
    case "tema":
    case "observacao":
    case "certificado":
    case "cnpj":
    case "cpf":
    case "unidade":
      return Joi.string().required();
    case "foto":
    case "foto2":
      return Joi.object().required();
    default:
      throw new Error("Field not found: " + field);
  }
};

export const getDefaultOfType = (field) => {
  switch (field) {
    case "endereco":
      return "optional";
    case "site":
      return "optional";
    case "telefone":
      return "optional";
    case "foto":
    case "foto2":
      return "image";
    default:
      return "text";
  }
};

export const translateType = (type) => {
  switch (type) {
    case "apresentacao":
      return "Apresentação";
    case "banner":
      return "Banner";
    case "brochura":
      return "Brochura";
    case "capa_facebook":
      return "Capa para facebook";
    case "cartaz":
      return "Cartaz";
    case "cartao_visitas":
      return "Cartão de visitas";
    case "comunicados":
      return "Comunicados";
    case "convites":
      return "Convites";
    case "cracha":
      return "Crachá";
    case "panfleto":
      return "Panfleto";
    case "alteracao_logotipo":
      return "Alteração de Logotipo";
    case "outdoor":
      return "Outdoor";
    case "brindes":
      return "Brindes";
    case "uniforme":
      return "Uniforme";
    case "postagem_redes":
      return "Postagem para redes sociais";
    case "inserir_numero":
      return "Inserir número";
    case "projeto_fachada":
      return "Projeto de fachada";
    case "outros":
      return "Outros";
    case "ebook":
      return "E-Book";
    default:
      return `${type}(error)`;
  }
};
