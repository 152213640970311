import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root':{
            margin: theme.spacing(1),
        },
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    flexando: {
        '& .MuiTypography-root': {
            margin: theme.spacing(1),
        },
        display: 'flex',
    },
    fontFaceNP: {
        fontFamily: "NoirPro",
    },
    table: {
        minWidth: 700,
      },
      tableRow: {
        '&:nth-of-type(odd)': {
          backgroundColor: "#8cbcff" 
        },
    }
}));
