import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    marginTop: "35%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#00d2c6",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    backgroundColor: "#00d2c6",
    margin: theme.spacing(3, 0, 2),
     
    "&:hover": {
      backgroundColor: "#2eb0a9",
    },
  },
  googleButton: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  checkbox: {
    marginLeft: theme.spacing(2),
  },
  buttons: {
    width: '100%', 
    height: "50px", 
    color: "white", 
    backgroundColor: "#00d2c6",
    [theme.breakpoints.down('sm')]: {
      width: "auto"
    },
    "&:hover": {
      backgroundColor: "#2eb0a9"
    },
  },
}));