export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const SET_ALERT = 'SET_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';

export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER_REQUESTS = "FETCH_USER_REQUESTS"

//Requests

export const FETCH_ALL_REQUESTS = "FETCH_ALL_REQUESTS"
export const START_LOADING_REQUEST = 'START_LOADING_REQUEST'
export const END_LOADING_REQUEST = "END_LOADING_REQUEST"
export const FETCH_REQUEST = 'FETCH_REQUEST'
export const DELETE_REQUEST = 'DELETE_REQUEST'
export const UPDATE_REQUEST = 'UPDATE_REQUEST'

//Avisos

export const FETCH_ALL_AVISOS = "FETCH_ALL_AVISOS"
export const FETCH_AVISO = 'FETCH_AVISO'
export const DELETE_AVISO = 'DELETE_AVISO'
export const UPDATE_AVISO = 'UPDATE_AVISO'
export const CLOSE_POPUP = 'CLOSE_POPUP'

// Helpers

export const CREATE_FEEDBACK = "CREATE_FEEDBACK"

// Creditas

export const CHECK_ELIGIBILITY = "CHECK_ELIGIBILITY"
export const FETCH_JOBTITLES = "FETCH_JOBTITLES"
export const FETCH_CARBRANDS = "FETCH_CARBRANDS"
export const FETCH_CARMODELS = "FETCH_CARMODELS"
export const FETCH_PROPOSALS = "FETCH_PROPOSALS"
export const FETCH_PROPOSAL = "FETCH_PROPOSAL"

// Agilpay
export const FETCH_AGILPAY_REQUESTS = "FETCH_AGILPAY_REQUESTS"
