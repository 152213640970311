import {combineReducers} from 'redux'

import {posts} from "./posts"
import {proposals} from "./credita"
import auth from './auth'
import {requests} from "./requests"
import {avisos} from "./avisos"
import {agilpay} from "./agilpay"
import { alert } from './alert'

export default combineReducers({ alert, posts, auth, requests, avisos, proposals, agilpay });
