import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root':{
            margin: theme.spacing(1),
        }
    },
    paper: {
        padding: theme.spacing(2),
        alignSelf: 'end',
        margin: 'auto',
        width:'50%',
    },
    message: {
      paddingBottom: 50,
      backgroundColor: "#ebebeb",
      display: 'flex',
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    fileInput: {
        width: '100%',
        margin: 'auto',
        marginTop: 20,
    },
    buttonSubmit: {
        marginTop: 20,
        marginBottom: 10,
        marginLeft: 6,
    },
    fontFaceNP: {
        fontFamily: "NoirPro",
    },

    tipoDeMaterial: {
        marginTop: 20,
        marginBottom: 10,
    },

    labelImage: {
        color: "gray"
    },
    select:{
        margin: theme.spacing(1),
        minWidth: 170,
    },
    checkboxInput: {
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(1),
    },
    field: {
        display: 'flex',
        width: '100%',
    }
}));
