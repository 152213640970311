import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root: {
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
            width: '80%'
        },
        '& .MuiBox-root': {
            margin: theme.spacing(1),
            width: '80%'
        }, 
    },
    field: {
        margin: theme.spacing(1),
            width: '80%'
    },
    paper: {
        padding: theme.spacing(2),
        marginLeft:'25%',
        width:'50%',
    },
    message: {
      paddingBottom: 50,
      backgroundColor: "#ebebeb",
      display: 'flex',
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    fileInput: {
        width: '97%',
        margin: '10px 0',
    },
    buttonSubmit: {
        margin: 10,
    },
    fontFaceNP: {
        fontFamily: "NoirPro",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      title: {
          width: '100%',
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
}));
