import * as api from '../api'

//Actions Creators
import { AUTH, FETCH_USER_REQUESTS, LOGOUT } from "../constants/actionTypes";
import { triggerAlert } from './alert';

export const signin = (formData, history) => async (dispatch) => {
  try{
    const { data } = await api.signIn(formData);
    dispatch({type: AUTH, data: data});

    history.push('/');
  }catch(e){
    dispatch(
      triggerAlert(
        e.response.data.message,
        "error"
      )
    )
    console.log(e)
  }
};

export const signup = (formData, history) => async (dispatch) => {
  try{
    const { data } = await api.signUp(formData);
    history.push('/');
  }catch(e){
    dispatch(
      triggerAlert(
        e.response.data.message,
        "error"
      )
    )
    console.log(e);
  }
};

export const isAdmin = () => {
  try{
    const user = () => JSON.parse(localStorage.getItem('profile'));
    if(user()?.result.role === "admin") return true;
  }
  catch(e){
    console.log(e);
  }
  return false;
}

export const changePass = (userData) => async (dispatch) => {
  try{
    const { data } = await api.changePass(userData);
    alert("Solicitação efetuada com sucesso");
    return data;
  }catch(e){
    dispatch(
      triggerAlert(
        e.message || "Não foi possível efetuar a solicitação",
        "error"
      )
    )
    console.log(e);
  }
}

export const changeAvatar = async (file) => {
  try{
    const { data } = await api.changeAvatar(file);
    return data;
  }catch(e){
    console.log(e);
  }
}

export const changeLogo = async (file) => {
  try{
    const { data } = await api.changeLogo(file);
    return data;
  }catch(e){
    console.log(e);
  }
}

export const sendFeedback = (message, history) => async (dispatch) =>{
  try{
      const { data } = await api.sendFeedback(message);
      alert("Solicitação enviada com sucesso")
      history.push(`/`);
  }catch (e) {
      alert("Ocorreu um erro durante a publicação do feedback, contate o suporte.")
      console.log(e);
      history.push('/');
  }
  
}

export const fetchImage = async (id) => {
  try{
    const {data} = await api.fetchAvatar(id);
    return data;
  }catch(e){
      console.log(e);
  }
}

export const fetchLogo = async (id, method) => {
  try{
    const {data} = await api.fetchLogo(id, method);
    return data;
  }catch(e){
      console.log(e);
  }
}

export const getUserRequests = () => async (dispatch) => {
  try{
    const { data } = await api.getUserRequests();
    dispatch({type: FETCH_USER_REQUESTS, payload: data});
  }catch(e){
    console.log(e);
  }
}