import React from "react";
import {
  Paper,
} from "@material-ui/core";
import { API_URL } from "../../../api/index";

const Aviso = (props) => {
  return (
    <Paper
      elevation={10}
    >
      <img src={API_URL + "/avisos/" + props.item._id} alt="memories" width="100%" />
    </Paper>)
};

export default Aviso;
