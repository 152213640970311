import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  appBar: {
    position: "relative",
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  heading: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '2em',
    fontWeight: 300,
  },
  image: {
    [theme.breakpoints.down('sm')]: {
    height: "80px",
    marginBottom: "-40px",  
    marginTop: '-40px',
    marginLeft: "auto",
    },
    marginLeft: 'auto',
    marginTop: '-10px',
    marginBottom: '-15px',

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },

  button: {
    marginBottom: '2px',
    marginRight: '6px',
    borderColor: "cyan",
    padding: "0px",
    borderRadius: "3px",
    marginLeft: '3px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: "2px",
      height: "40px",
    },
  },

  leterring: {
    color: "#00d2c6"

  },

  profile: {
    display: 'flex',
    alignItems: "flex-end",
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: '25px',
      marginBottom: "2px",
      justifyContent: 'center',
    },
  },  

  userName: {
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: "2px",
      height: "40px",
    },
  },
  brandContainer: {
    marginLeft: "10px",
    display: 'flex',
    alignItems: 'center',
  },
  purple: {
    marginLeft: "10px",
    margin: "auto",
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500], 
  },
  fontFaceNP: {
    fontFamily: "NoirPro",
  },
}));
