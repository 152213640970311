import {
    FETCH_ALL_AVISOS,
    DELETE_AVISO,
    CLOSE_POPUP
  } from '../constants/actionTypes'
  export const avisos = (state = { isLoading: true, avisos: [], popup: true}, action) => {
    switch (action.type) {
      case FETCH_ALL_AVISOS:
        return {
          ...state, avisos: action.payload.data.sort((b, a) =>  new Date(a.createAt) - new Date(b.createAt))
        };
      case DELETE_AVISO:
        return {
          ...state, avisos: state.avisos.filter(a => a._id !== action.payload)
        };
      case CLOSE_POPUP:
        return {
          ...state, popup: false
        };
      default:
        return state;
    }
  }
  